import React, { useCallback, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import { CloseIcon } from '../icons/CloseIcon'
import { useTheme } from '@mui/material'

type ReturnType = {
  openModal: () => void
  closeModal: () => void
  renderModal: () => React.ReactNode
}

export const useCompactModal = (
  body: React.ReactNode,
  position: 'right' | 'center'
): ReturnType => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)

  const openModal = useCallback(() => setIsOpen(true), [])
  const closeModal = useCallback(() => setIsOpen(false), [])

  const renderModal = () => {
    return (
      <Modal open={isOpen} style={{ margin: '40px' }}>
        <Box
          sx={
            position === 'right'
              ? {
                  [theme.breakpoints.up('md')]: {
                    width: '50%',
                    transform: 'translate(0%,-50%)',
                  },
                  transform: 'translate(-50%,-50%)',
                  width: '100%',
                }
              : {
                  transform: 'translate(-50%,-50%)',
                  width: '100%',
                }
          }
          style={{
            backgroundColor: 'rgba(255, 255, 255, 1)',
            padding: '8px',
            borderRadius: '8px',
            position: 'absolute',
            textAlign: 'center',
            top: '50%',
            left: '50%',
          }}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              component="span"
              onClick={() => setIsOpen(false)}
              size="large"
              style={{ color: '#000' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {body}
        </Box>
      </Modal>
    )
  }

  return {
    openModal: openModal,
    closeModal: closeModal,
    renderModal: () => renderModal(),
  }
}
