import { AxiosPromise } from 'axios'
import client from './client'

type AuthResponse = {
  detail: string
  remaining_attempts: number
  cooloff_time: number
}

export const login = (
  email: string,
  password: string
): AxiosPromise<AuthResponse> => {
  /* eslint-disable */
  const qs = require('qs')
  const params = qs.stringify({
    email,
    password,
  })
  return client.post('api/login/', params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export const checkAuth = async () => {
  return client.get('check_auth/', {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}
