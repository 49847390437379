import React, { FC, useMemo } from 'react'

/* mui */
import { Stack, Box, Button, useTheme, Snackbar } from '@mui/material'

/* icons */
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded'

/* helpers */
import { getHasHighCheckPriority } from '../helpers/getHasHighCheckPriority'

/* component */
import { Copyright } from '../components/molecules/Copyright'
import { ErrordOverlay } from '../components/molecules/ErrordOverlay'
import { QRImage } from '../components/molecules/QRImage'
import { BasicResultMenu } from '../components/organisms/BasicResult'
import { MedicineResult } from '../components/organisms/MedicineResult'
import { QrImagesModalBody } from '../components/templates/QrImagesModalBody'
import { InitialScreenBody } from '../components/templates/InitialScreenBody'
import { RobotToast } from '../components/templates/RobotToast'
import { CustomFab } from '../components/molecules/CustomFab'

/* hooks */
import { usePrescriptions } from '../hooks/usePrescriptions'
import { useSelectedPresc } from '../hooks/useSelectedPresc'
import { useInccorectPrescLabelReportModal } from '../hooks/useInccorectPrescLabelReportModal'
import { useModal } from '../hooks/useModal'
import { usePrescriptionImageBox } from '../hooks/usePrescriptionImageBox'
import { useEditPresc } from '../hooks/useEditPresc'
import { useBasicResult } from '../hooks/useBasicResultTab'
import { useSoundNotification } from '../hooks/useMakeNotificationButton'
import { DrawerToggleButton } from '../hooks/useDrawerToggle'
import { useMedicationAlert, openButton } from '../hooks/useMedicationAlert'
import { useChatModalOpenButton } from '../hooks/useModalOpenButton'
import { useChatModal } from '../hooks/useChatModal'
import { useReportPresc } from '../hooks/useReport'
import { useUpdateNotifyModal } from '../hooks/useUpdateNotifyModal'
import { useSupportChatNotification } from '../hooks/useSupportChatNotification'
import useReactRouter from 'use-react-router'
import { useAnnotate } from '../hooks/useAnnotate'
import { localStorageWrapper } from '../helpers/localStorage'
import { useRobotToast } from '../hooks/useRobotToast'
import { useCamera } from '../hooks/useCameraModal'
import { SendToMobile } from '@mui/icons-material'
import { generateCsv } from '../apis/prescription'
import { useGenerateCsvButton } from '../hooks/useGenerateCsvButton'

const IndexPage: FC = () => {
  const { history } = useReactRouter()
  const {
    AdditionalPrescSnackBar,
    prescriptions,
    loadInitialPrescriptions,
    LoadingOverlay,
  } = usePrescriptions()
  const { selectedPresc: userPresc, selectOcrRequest } = useSelectedPresc({
    prescriptions,
  })

  const isAnnotator = localStorageWrapper.getItem('is_annotator') || false
  const theme = useTheme()
  const {
    AnnotateDrawerButtons,
    selectedPresc: annotatePresc,
    loadAnnotatePrescList,
    SearchBox,
  } = useAnnotate()
  const selectedPresc = isAnnotator ? annotatePresc : userPresc
  const loadPresc = isAnnotator
    ? loadAnnotatePrescList
    : loadInitialPrescriptions

  const {
    editBasicResult,
    editRpnumberItem,
    editMedResult,
    updatingPrescription,
  } = useEditPresc({
    prescription: selectedPresc,
    refreshPrescriptions: loadPresc,
  })

  const {
    reportBasicResult,
    reportMedResult,
    openModal,
    renderSnackBar,
    ReportSendingOverlay,
  } = useReportPresc({
    prescription: selectedPresc,
    onSuccess: loadPresc,
  })

  // const { renderChatModal, ChatModalButton } = useChatModal(selectedPresc?.id)
  const { renderCameraModal, OpenButton, DuringOcrOverlay } = useCamera()

  const { MedicationModal, onSetIsOpen } = useMedicationAlert(
    selectedPresc?.id,
    selectedPresc?.low_accuracy_alerts,
    selectedPresc?.reshape_reports,
    selectedPresc?.gigi_alerts,
    selectedPresc?.med_change_alerts
  )

  const { NotificationSoundButton, NotificationOverlay } = useSoundNotification(
    prescriptions
  )
  const { ChatNotificationSnackBars } = useSupportChatNotification()

  const CsvButton = useGenerateCsvButton(selectedPresc?.id)

  const DashboardNavigateButton = (
    <>
      <Button
        sx={{ width: '100%', whiteSpace: 'pre-wrap', color: '#fff' }}
        onClick={() => history.push('/beta/dashboard')}
      >
        {'ダッシュボード'}
      </Button>
    </>
  )

  const SupportChatPageNavigateButton = (
    <>
      <Button
        sx={{ width: '100%', whiteSpace: 'pre-wrap', color: '#fff' }}
        onClick={() => history.push('/support_chat')}
      >
        {'サポートチャット'}
      </Button>
    </>
  )
  const MedChangePageNavigateButton = (
    <>
      <Button
        sx={{ width: '100%', whiteSpace: 'pre-wrap', color: '#fff' }}
        onClick={() => history.push('/med_change')}
      >
        {'採用薬の変更登録'}
      </Button>
    </>
  )

  const drawerButtomButtons = [
    // DashboardNavigateButton,
    // SupportChatPageNavigateButton,
    MedChangePageNavigateButton,
  ]
  const { renderModal: renderUpdateNotifyModal } = useUpdateNotifyModal()

  const prescImages = useMemo(() => selectedPresc?.presc_images || [], [
    selectedPresc,
  ])

  const prescImagesArea = useMemo(
    () => selectedPresc?.basic_result.処方箋存在領域.selected_option || [],
    [selectedPresc]
  )

  const {
    prescImageShown,
    PrescImageBox,
    PrescImageToggleButton,
  } = usePrescriptionImageBox(prescImages, prescImagesArea)

  const { essentialBasicResultShown, BasicResultsTab } = useBasicResult()

  const {
    openModal: openQrImageModal,
    renderModal: renderQrImageModal,
  } = useModal(
    selectedPresc && <QrImagesModalBody prescription={selectedPresc} />,
    'center'
  )

  const { FavoriteBox } = useRobotToast(selectedPresc, loadPresc)

  if (!selectedPresc) {
    return (
      <>
        {LoadingOverlay}
        {isAnnotator ? (
          AnnotateDrawerButtons
        ) : (
          <DrawerToggleButton
            PrescImageToggleButton={PrescImageToggleButton}
            prescriptions={prescriptions}
            selectOcrRequest={selectOcrRequest}
            BottomButtons={drawerButtomButtons}
          />
        )}
        <InitialScreenBody />
        {DuringOcrOverlay}
        {OpenButton}
        {renderCameraModal()}
        {ChatNotificationSnackBars}
      </>
    )
  }

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        minHeight: '100vh',
        backgroundColor: theme.palette.secondary.light,
      }}
    >
      {DuringOcrOverlay}
      {ReportSendingOverlay}
      {NotificationOverlay}
      {isAnnotator ? (
        AnnotateDrawerButtons
      ) : (
        <DrawerToggleButton
          selectedPresc={selectedPresc}
          PrescImageToggleButton={PrescImageToggleButton}
          prescriptions={prescriptions}
          selectOcrRequest={selectOcrRequest}
          BottomButtons={drawerButtomButtons}
        />
      )}

      {PrescImageBox}
      <Box
        sx={{
          [theme.breakpoints.down('md')]: prescImageShown
            ? {
                display: 'none',
              }
            : {
                width: '100%',
              },
        }}
        width="50%"
      >
        <div
          style={{
            position: 'relative',
            width: '100%',
            overflow: 'scroll',
            height: '100vh',
          }}
        >
          {isAnnotator && SearchBox}
          {selectedPresc.was_error && ErrordOverlay}
          {!selectedPresc.was_error && MedicationModal}
          <Stack bgcolor={'primary.main'} p={2} spacing={1}>
            <Box>
              {BasicResultsTab}
              {openButton(() => onSetIsOpen(true))}
              <CustomFab
                sx={{
                  '&:hover': {
                    backgroundColor: theme.palette.primary.translucent,
                    color: '#fff',
                  },
                  bottom: 90,
                }}
                onClick={openModal}
              >
                <CampaignRoundedIcon style={{ fontSize: 35 }} />
              </CustomFab>

              <Stack
                p={1}
                bgcolor="#fff"
                borderRadius="0px 0px 8px 8px"
                direction="row"
                alignItems="flex-start"
              >
                <Stack
                  sx={{ boxSizing: 'border-box' }}
                  spacing={2}
                  p={1}
                  width="50%"
                  display="inline-block"
                >
                  <BasicResultMenu
                    editBasicResult={editBasicResult}
                    reportBasicResult={reportBasicResult}
                    basicResult={selectedPresc.basic_result}
                    prescId={selectedPresc.id}
                    isCheckboxDisabled={updatingPrescription}
                    getHasHighCheckPriority={getHasHighCheckPriority}
                    isEssentialBasicResultShown={essentialBasicResultShown}
                  />
                </Stack>
                <Stack
                  sx={{ boxSizing: 'border-box' }}
                  p={1}
                  width="50%"
                  display="inline-block"
                >
                  <QRImage
                    prescription={selectedPresc}
                    isLoadingNewQr={updatingPrescription}
                    openModal={openQrImageModal}
                  />
                  {CsvButton}
                  {/* {FavoriteBox} */}
                </Stack>
              </Stack>
            </Box>
          </Stack>
          {renderSnackBar()}
          <Stack px={2}>
            <MedicineResult
              prescription={selectedPresc}
              editMedResult={editMedResult}
              editRpnumberItem={editRpnumberItem}
              reportMedResult={reportMedResult}
            />
          </Stack>

          <Box p={2}>
            <Copyright />
          </Box>
        </div>
        {ChatNotificationSnackBars}
        {AdditionalPrescSnackBar()}
        {renderQrImageModal()}
        {OpenButton}
        {renderCameraModal()}
      </Box>
    </div>
  )
}

export default IndexPage
