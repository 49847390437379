import React, { FC, memo, NamedExoticComponent } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {
  Stack,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from '@mui/material'
import { CloseIcon } from '../../icons/CloseIcon'
import { Pharmacy } from '../../types/models/Pharmacy'

type Props = {
  isOpen: boolean
  onClose: () => void
  pharmacies: Pharmacy[]
  selectedPharmacy?: Pharmacy
  selectPharmacy: (pharmacy_id: string) => void
}

export const PharmacyDrawerList: FC<Props> = ({
  isOpen,
  onClose,
  pharmacies,
  selectedPharmacy,
  selectPharmacy,
}) => {
  const theme = useTheme()
  return (
    <Drawer
      anchor={'left'}
      open={isOpen}
      onClose={onClose}
      sx={{
        position: 'relative',
        '& .MuiDrawer-paper': {
          backgroundColor: theme.palette.primary.dark,
        },
      }}
      style={{
        opacity: 0.9,
        zIndex: 1301,
      }}
    >
      <Stack direction="column">
        <Box
          sx={{ height: '5vh' }}
          left={0}
          top={0}
          bgcolor={'primary.dark'}
          color="#fff"
          p={1}
        >
          <Button
            onClick={onClose}
            startIcon={<CloseIcon />}
            style={{ color: '#fff' }}
          >
            {selectedPharmacy ? selectedPharmacy.name : ''}
          </Button>
        </Box>
        <Box
          overflow="scroll"
          bgcolor={'primary.dark'}
          color="#fff"
          sx={{ width: 200, height: '100vh' }}
          role="presentation"
        >
          <List>
            {selectedPharmacy &&
              pharmacies.map((pharmacy) => (
                <DrawerItem
                  key={pharmacy.id}
                  pharmacy={pharmacy}
                  selectPharmacy={selectPharmacy}
                  isSelected={pharmacy.id === selectedPharmacy.id}
                />
              ))}
          </List>
        </Box>
      </Stack>
    </Drawer>
  )
}

type DrawerProps = {
  pharmacy: Pharmacy
  selectPharmacy: (pharmacy_id: string) => void
  isSelected: boolean
}

const DrawerItem: NamedExoticComponent<DrawerProps> = memo(
  ({ pharmacy, selectPharmacy, isSelected }) => {
    const theme = useTheme()
    return (
      <ListItem
        button
        onClick={() => selectPharmacy(pharmacy.id)}
        key={pharmacy.id}
        style={
          isSelected
            ? {
                backgroundColor: '#fff',
                color: theme.palette.primary.dark,
              }
            : { borderTop: '0.5px solid #fff' }
        }
      >
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          width="100%"
        >
          <Box>
            <ListItemText primary={pharmacy.name} />
          </Box>
        </Stack>
      </ListItem>
    )
  },
  (prev, next) => {
    return (
      prev.pharmacy.name === next.pharmacy.name &&
      prev.isSelected === next.isSelected
    )
  }
)

DrawerItem.displayName = 'DrawerItem'
