import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material'
import { postFeatureRequest } from '../../apis/dashboard'
import React, { ReactElement, FC } from 'react'

type Props = { CameraComponent: React.ReactNode; SubmitButton: React.ReactNode }

export const CameraModalBody: FC<Props> = ({
  CameraComponent,
  SubmitButton,
}) => {
  return (
    <div style={{ marginLeft: 16, marginRight: 16, marginBottom: 16 }}>
      {CameraComponent}
      <Box sx={{ position: 'absolute', right: 20, bottom: 20 }}>
        {SubmitButton}
      </Box>
    </div>
  )
}
