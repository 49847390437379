import client from './client'
import { AxiosPromise } from 'axios'

import { Prescription } from '../types/models/Presc'
import { Pharmacy } from '../types/models/Pharmacy'

export const getPrescriptions = (): AxiosPromise<Prescription[]> =>
  client.get('/prescriptions')

export const getAnnotatePrescList = (
  pharmacy: Pharmacy
): AxiosPromise<Prescription[]> => client.get(`${pharmacy.id}/prescriptions`)

export const changeFavoriteStatus = (
  prescId: string,
  isFavorite: boolean
): AxiosPromise => {
  const params = { isFavorite }
  return client.post(`prescriptions/${prescId}/is_favorite/`, params)
}

export const createPrescription = (images: string[]): AxiosPromise<string> => {
  const formData = new FormData()
  images.forEach((image, index) => {
    formData.append(`images[${index}]`, image)
  })
  return client.post('/request_event/', formData)
}

export const generateCsv = (prescId: string) => {
  return client.post<void>(`prescriptions/${prescId}/generate_csv/`)
}
