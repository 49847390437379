import React, { useState, useEffect, useMemo } from 'react'
import { Box, Button } from '@mui/material'
import useSound from 'use-sound'
import sound from '../components/atoms/notification.mp3'
import { Prescription } from '../types/models/Presc'
import { SoundIcon } from '../icons/SoundIcon'
import { MuteIcon } from '../icons/MuteIcon'

export const useSoundNotification = (prescriptions: Prescription[]) => {
  const [isSoundAllowed, setIsSoundAllowed] = useState(false)
  const [playSound] = useSound(sound)

  useEffect(() => {
    isSoundAllowed && playSound()
  }, [prescriptions.length])

  const activateNotification = () => {
    setIsSoundAllowed(true)
    playSound()
  }

  const inactivateNotification = () => {
    setIsSoundAllowed(false)
  }

  const NotificationSoundButton = useMemo(
    () => (
      <>
        <Button
          sx={{ width: '100%', whiteSpace: 'pre-wrap', color: '#fff' }}
          startIcon={isSoundAllowed ? <MuteIcon /> : <SoundIcon />}
          onClick={
            isSoundAllowed ? inactivateNotification : activateNotification
          }
        >
          {isSoundAllowed ? '離席モードOFF' : `離席モード\nQRの完成を音で通知`}
        </Button>
      </>
    ),
    [isSoundAllowed]
  )

  const NotificationOverlay = useMemo(
    () =>
      isSoundAllowed && (
        <Box
          sx={{
            opacity: 0.6,
          }}
          bottom={0}
          textAlign="center"
          position="fixed"
          width="100%"
          zIndex={1300}
          left={0}
          bgcolor="#000"
          color="#fff"
          p={1}
          borderRadius="0px 0px 8px 0px"
        >
          離席モード中
        </Box>
      ),
    [isSoundAllowed]
  )

  return { NotificationSoundButton, NotificationOverlay }
}
