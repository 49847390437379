import React, { useEffect, useState } from 'react'
import { UpdateNotifyModalBody } from '../components/templates/UpdateNotifyModalBody'
import { useModal } from './useModal'

export const useUpdateNotifyModal = () => {
  useEffect(() => {
    openModal()
  }, [])

  const { openModal, renderModal } = useModal(
    <UpdateNotifyModalBody />,
    'center'
  )

  return { renderModal }
}
