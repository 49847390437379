import React, { FC, useState, useEffect } from 'react'
import { Fab, Button, Stack, Box, useTheme } from '@mui/material'

/* icons */
import { FavoriteIcon } from '../icons/FavoriteIcon'
import { FavoriteBorderIcon } from '../icons/FavoriteBorderIcon'
import { changeFavoriteStatus } from '../apis/prescription'
import { Prescription } from '../types/models/Presc'
import { RobotToast } from '../components/templates/RobotToast'

export const useRobotToast = (
  presc: Prescription | undefined,
  loadPresc: () => void
) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isFavorite, setIsFavorite] = useState(false)
  const theme = useTheme()

  useEffect(() => {
    if (!presc) return
    setIsFavorite(presc.is_favorite)
  }, [presc])

  const pushFavorite = async () => {
    setIsFavorite(!isFavorite)
    !isFavorite && setIsOpen(true)
    presc && (await changeFavoriteStatus(presc.id, !isFavorite))
    loadPresc()

    setTimeout(() => {
      setIsOpen(false)
    }, 5 * 1000)
  }

  const LikeButton = (
    <Fab
      disableRipple
      sx={{
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: 'rgba(255, 255, 255, 0)',
        },
        color: isFavorite
          ? theme.palette.warning.main
          : 'rgba(255, 255, 255, 0)',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        boxShadow: 'none',
      }}
      onClick={pushFavorite}
    >
      <FavoriteBorderIcon
        style={{
          position: 'absolute',
          right: 0,
          fontSize: 36,
          color: 'black',
        }}
      />
      <FavoriteIcon
        style={{
          position: 'absolute',
          right: 0,
          fontSize: 36,
        }}
      />
    </Fab>
  )

  const FavoriteBox = (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{ height: 80, alignItems: 'center' }}
    >
      <Box sx={{ pl: 4 }}>
        <RobotToast open={isOpen} />
      </Box>
      {LikeButton}
    </Stack>
  )

  return {
    FavoriteBox,
  }
}
