import { Stack } from '@mui/material'
import React, { FC } from 'react'
import { localStorageWrapper } from '../../helpers/localStorage'
import { Prescription } from '../../types/models/Presc'

type Props = {
  prescription: Prescription
}

export const QrImagesModalBody: FC<Props> = ({ prescription }) => {
  const qrSize = localStorageWrapper.getItem('qr_size') || '360px'
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '8px',
      }}
    >
      <Stack direction="row" spacing={2}>
        {prescription.qr_images.map((image) => (
          <div key={image.id}>
            <img src={image.image_url} alt="処方箋のQR画像" width={qrSize} />
          </div>
        ))}
      </Stack>
    </div>
  )
}
