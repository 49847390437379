import ReportIcon from '@mui/icons-material/Report'

import { FC } from 'react'
import { SvgIconProps } from '@mui/material/SvgIcon'
import { CSSProperties } from 'react'

type Props = {
  style?: CSSProperties
}

export const CircleExclamationIcon: FC<Props> = ({ style }) => {
  return <ReportIcon style={style} />
}
