import { Prescription } from '../types/models/Presc'

export const getUniquePrescriptions = (maybeDuplicatedList: Prescription[]) => {
  const uniqueList = [] as Prescription[]
  console.log('maybeDuplicatedList', maybeDuplicatedList)
  maybeDuplicatedList.forEach((item) => {
    const duplicatedItem = uniqueList.find(
      (uniqueItem) => uniqueItem.id === item.id
    )
    !duplicatedItem && uniqueList.push(item)
  })

  return uniqueList
}
