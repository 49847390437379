import React, { CSSProperties, FC } from 'react'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormLabel from '@mui/material/FormLabel'
import { Box, Stack, Typography, useTheme } from '@mui/material'

type Item = {
  label: string
  value: string
  isSelected: boolean
}

type Props = {
  onChange: (value: string) => void
  checkboxList: Item[]
  label: string
  isDisabled: boolean
  hasHighCheckPriority: boolean
  maxWidth: number
  isReported: boolean
}

export const Checkboxes: FC<Props> = ({
  onChange,
  checkboxList,
  label,
  isDisabled,
  hasHighCheckPriority,
  maxWidth,
  isReported,
}) => {
  const theme = useTheme()
  return (
    <Box
      pl={1}
      sx={{
        maxWidth: maxWidth,
      }}
      color={theme.palette.primary.dark}
    >
      <FormControl sx={{ width: '100%' }}>
        <FormLabel
          sx={{
            '&.Mui-focused': {
              color: isReported
                ? theme.palette.primary.dark
                : theme.palette.secondary.dark,
            },
            color: hasHighCheckPriority
              ? theme.palette.warning.main
              : isReported
              ? theme.palette.primary.dark
              : theme.palette.secondary.dark,
            height: 12,
            mb: 1.5,
            fontWeight: isReported ? 'bold' : 'initial',
          }}
          component="legend"
          color={hasHighCheckPriority ? 'warning' : 'secondary'}
        >
          <Stack direction="row" spacing={1}>
            <Typography fontSize={14}>{label}</Typography>
            {isReported && <Typography>✔️</Typography>}
          </Stack>
        </FormLabel>
        <FormGroup row>
          {checkboxList.map((item) => (
            <FormControlLabel
              sx={{ color: '#000', width: '47%' }}
              key={item.value}
              control={
                <Checkbox
                  sx={{ height: 24 }}
                  size="small"
                  color="info"
                  checked={item.isSelected}
                  onChange={(e) => onChange(e.target.value)}
                  value={item.value}
                  disabled={isDisabled}
                />
              }
              label={item.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    </Box>
  )
}
