import { Box, ButtonBase, Stack, Typography, useTheme } from '@mui/material'
import React, { FC, memo, NamedExoticComponent, useState } from 'react'
import { SelectBox } from '../molecules/SelectBox'
import { SELECTBOX_MAX_WIDTH } from '../../constants/const'
import { getHasHighCheckPriority } from '../../helpers/getHasHighCheckPriority'
import { toCircledNum } from '../../helpers/toCircledNum'
import { MedResult, Prescription, RpnumberItem } from '../../types/models/Presc'
import { ReportMedResultParams } from '../../apis/reportPresc'
import { keyframes } from '@emotion/react'

type Props = {
  prescription: Prescription
  editRpnumberItem: (props: EditRpNumberProps) => void
  editMedResult: (props: EditMedResultProps) => void
  reportMedResult: (rp_num: number, is_reported: boolean) => void
}

type EditMedResultProps = {
  rpIndex: number
  medIndex: number
  key: '薬品名称' | '用量'
  value:
    | MedResult['薬品名称']['selected_option']
    | MedResult['用量']['selected_option']
}

type EditRpNumberProps = {
  rpIndex: number
  key: '用法名称' | '調剤数量'
  value:
    | RpnumberItem['用法名称']['selected_option']
    | RpnumberItem['調剤数量']['selected_option']
}

export const MedicineResult: NamedExoticComponent<Props> = memo(
  ({ reportMedResult, prescription, editRpnumberItem, editMedResult }) => {
    const theme = useTheme()
    return (
      <>
        {prescription.med_result.map((rp, rpIndex) => (
          <div
            key={`${prescription.ocr_request_event_id}-${rp['RP番号'].selected_option}`}
          >
            <ButtonBase
              sx={{
                display: 'flex',
                width: '100%',
                boxSizing: 'border-box',
                border: rp.is_reported ? '2px solid' : '2px solid #fff',
                bgcolor: '#fff',
                color: rp.is_reported
                  ? theme.palette.primary.dark
                  : theme.palette.secondary.dark,
                borderRadius: '8px',
                overflowWrap: 'break-word',
                mt: 2,
                pb: 4,
                overflow: 'hidden',
                pl: 2,
                pt: 2,
              }}
              onClick={() =>
                reportMedResult(rp['RP番号'].selected_option, !rp.is_reported)
              }
            >
              <Stack width="100%">
                <Stack direction="row" justifyContent="space-between">
                  <Box>
                    <Typography mb={1}>
                      <h3 style={{ margin: 0 }}>
                        {'処方内容' +
                          toCircledNum(Number(rp['RP番号'].selected_option))}
                      </h3>
                    </Typography>
                  </Box>
                  {rp.is_reported && (
                    <Box pr={2}>
                      <Typography>✔️</Typography>
                    </Box>
                  )}
                </Stack>
                {rp.med_result.map((med, medIndex) => (
                  <div
                    key={`${prescription.ocr_request_event_id}-${rp['RP番号'].selected_option}-${med['薬品名称'].selected_option}`}
                  >
                    <Stack direction="row" pr={2}>
                      <Box mr={3} width="70%">
                        <SelectBox
                          onChangeChoice={(value: string) =>
                            editMedResult({
                              rpIndex,
                              medIndex,
                              key: '薬品名称',
                              value,
                            })
                          }
                          choices={med['薬品名称'].options}
                          selectedChoice={med['薬品名称'].selected_option}
                          maxWidth={SELECTBOX_MAX_WIDTH.medicine}
                          hasHighCheckPriority={getHasHighCheckPriority(
                            med['薬品名称'].user_check_priority
                          )}
                        />
                      </Box>
                      <Box width="120px">
                        <SelectBox
                          onChangeChoice={(value: string) =>
                            editMedResult({
                              rpIndex,
                              medIndex,
                              key: '用量',
                              value: value.replace(
                                med['単位名'].selected_option,
                                ''
                              ),
                            })
                          }
                          choices={med['用量'].options.map(
                            (dose) => dose + med['単位名'].selected_option
                          )}
                          selectedChoice={
                            med['用量'].selected_option +
                            med['単位名'].selected_option
                          }
                          hasHighCheckPriority={getHasHighCheckPriority(
                            Math.max(
                              med['用量'].user_check_priority,
                              med['単位名'].user_check_priority
                            )
                          )}
                          maxWidth={SELECTBOX_MAX_WIDTH.medicine}
                        />
                      </Box>
                    </Stack>
                  </div>
                ))}
                <Stack pr={2}>
                  <Box>
                    <hr
                      style={{
                        border: 'none',
                        backgroundColor: '#F4F4F4',
                        width: '100%',
                        height: '2px',
                      }}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" pr={2}>
                  <Box width="70%" mr={3}>
                    <SelectBox
                      onChangeChoice={(value: string) =>
                        editRpnumberItem({
                          rpIndex,
                          key: '用法名称',
                          value,
                        })
                      }
                      choices={rp['用法名称'].options}
                      selectedChoice={rp['用法名称'].selected_option}
                      maxWidth={SELECTBOX_MAX_WIDTH.usage}
                      hasHighCheckPriority={getHasHighCheckPriority(
                        rp['用法名称'].user_check_priority
                      )}
                    />
                  </Box>
                  <Box width="120px">
                    <SelectBox
                      onChangeChoice={(value: string) =>
                        editRpnumberItem({
                          rpIndex,
                          key: '調剤数量',
                          value,
                        })
                      }
                      choices={rp['調剤数量'].options}
                      selectedChoice={rp['調剤数量'].selected_option}
                      maxWidth={SELECTBOX_MAX_WIDTH.usage}
                      hasHighCheckPriority={getHasHighCheckPriority(
                        rp['調剤数量'].user_check_priority
                      )}
                    />
                  </Box>
                </Stack>
              </Stack>
            </ButtonBase>
          </div>
        ))}
      </>
    )
  }
)

MedicineResult.displayName = 'MedicineResult'
