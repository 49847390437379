import client from './client'
import { AxiosPromise } from 'axios'

import { Prescription } from '../types/models/Presc'

export const getAdditionalPresc = (
  timestamp: Prescription['request_event_created_at']
): AxiosPromise<Prescription[]> => {
  return client.get('/additional_presc/', {
    params: {
      timestamp,
    },
  })
}
