import React, { useState, useCallback, FC } from 'react'
import { memo, NamedExoticComponent } from 'react'
import { Prescription } from '../types/models/Presc'
import { Box, Button } from '@mui/material'
import { MenuIcon } from '../icons/MenuIcon'
import { DrawerPrescList } from '../components/organisms/Drawer'
import { Pharmacy } from '../types/models/Pharmacy'
import { PharmacyDrawerList } from '../components/organisms/PharmacyDrawer'
import { usePharmacy } from './usePharmacy'
import { useAnnotatePharmacyDrawerButton } from './useAnnotatePharmacyDrawer'
import { useAnnotatePrescDrawerButton } from './useAnnotatePrescDrawer'
import { SearchBar } from '../components/organisms/SerachBar'

export const useAnnotate = () => {
  const {
    AnnotatePharmacyDrawerButton,
    selectedPharmacy,
  } = useAnnotatePharmacyDrawerButton()
  const {
    AnnotatePrescDrawerButton,
    selectedPresc,
    loadAnnotatePrescList,
    selectOcrRequest,
    prescriptions,
  } = useAnnotatePrescDrawerButton(selectedPharmacy)

  const AnnotateDrawerButtons = (
    <>
      {AnnotatePharmacyDrawerButton}
      {AnnotatePrescDrawerButton}
    </>
  )

  const request_event_ids = prescriptions.map(
    (presc) => presc.ocr_request_event_id
  )

  const SearchBox = (
    <SearchBar onClick={selectOcrRequest} options={request_event_ids} />
  )

  return {
    AnnotateDrawerButtons,
    selectedPresc,
    loadAnnotatePrescList,
    SearchBox,
  }
}
