import { Snackbar, useTheme } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import { useState } from 'react'
import { SupportChatMessage } from '../../types/models/SupportChatMessage'
import { useHistory } from 'react-router-dom'

export const ChatNotificationSnackbar = ({
  message,
  index,
}: {
  message: SupportChatMessage
  index: number
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const theme = useTheme()

  const onClose = () => {
    setIsOpen(false)
  }

  const history = useHistory()
  const handleNavigate = () => {
    history.push('/support_chat')
  }

  return (
    <Snackbar
      key={message.id}
      style={{
        zIndex: 100,
        bottom: `${index * 75 + 10}px`,
        marginRight: '60px',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={isOpen}
      onClose={onClose}
    >
      <MuiAlert
        sx={{ bgcolor: theme.palette.primary.translucent }}
        elevation={6}
        variant="filled"
        onClick={handleNavigate}
      >
        <div>サポートチャットにメッセージが届きました</div>
        <div>
          {message.text.length > 20
            ? message.text.substring(0, 20) + '...'
            : message.text}
        </div>
      </MuiAlert>
    </Snackbar>
  )
}
