import { CSSProperties, FC } from 'react'
import FavoriteBorder from '@mui/icons-material/FavoriteBorder'

type Props = {
  style?: CSSProperties
}

export const FavoriteBorderIcon: FC<Props> = ({ style }) => {
  return <FavoriteBorder style={style} />
}
