import { Box, Card, Stack, Typography } from '@mui/material'
import { ApexOptions } from 'apexcharts'
import { FC } from 'react'
import Chart from 'react-apexcharts'

type Props = {
  title: string
  xTitle: string
  data: { label: number; eventCount: number; busynessScore: number }[]
}

export const DashboardGraph: FC<Props> = ({ title, data, xTitle }) => {
  const converted_data: ApexAxisChartSeries = [
    {
      name: '患者数',
      type: 'bar',
      data: data.map((item) => item.eventCount),
    },
    {
      name: '忙しさスコア',
      type: 'line',
      data: data.map((item) => item.busynessScore || 0), // busynessScoreを追加（nullの場合は0にする）
    },
  ]

  const [max, tickAmount] = (() => {
    const max = Math.max.apply(Math, [
      10,
      ...data.map((item) => item.eventCount),
    ])
    const maxBase = Math.pow(10, Math.floor(Math.log10(max)))
    const bigDigit = Math.ceil(max / maxBase)
    return [
      maxBase * bigDigit,
      bigDigit === 1
        ? 5
        : bigDigit <= 4
        ? 2 * bigDigit
        : bigDigit <= 8
        ? bigDigit
        : 6,
    ]
  })()

  const options: ApexOptions = {
    dataLabels: {
      enabled: false,
    },
    colors: ['#8884d8', '#82ca9d'],
    chart: {
      id: title,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: data.map((item) => item.label),
      labels: {
        hideOverlappingLabels: true,
        formatter(value) {
          return value && Number(value) % 2 === 1 ? value.toString() : ''
        },
        rotate: 0,
      },
      title: {
        text: xTitle,
        style: {
          fontSize: '1em',
        },
      },
    },
    yaxis: {
      labels: {
        formatter(val) {
          return val.toFixed(0)
        },
      },
      max,
      tickAmount,
    },
    markers: {
      size: 2,
      colors: ['#82ca9d'],
      strokeColors: '#82ca9d',
      strokeWidth: 2,
      shape: 'circle',
    },
    stroke: {
      width: [0, 2],
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
        borderRadius: 0,
        distributed: false,
      },
    },
    fill: {
      colors: ['#8884d8'],
      opacity: 1,
    },
  }
  return (
    <Stack
      direction="column"
      sx={{
        height: '100%',
      }}
    >
      <Typography variant="h6" align="center">
        {title}
      </Typography>
      <Card sx={{ flexGrow: 1, px: 2, py: 1 }}>
        <Chart
          type="line"
          options={options}
          series={converted_data}
          height="100%"
        ></Chart>
      </Card>
    </Stack>
  )
}
