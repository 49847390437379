import { useMemo, useState } from 'react'
import { Prescription } from '../types/models/Presc'

type Params = {
  prescriptions: Prescription[]
}

export const useSelectedPresc = ({ prescriptions }: Params) => {
  const [selectedOcrRequestId, setSelectedOcrRequestId] = useState<
    Prescription['ocr_request_event_id']
  >()

  const findPrescription = (id: Prescription['ocr_request_event_id']) => {
    const prescription = prescriptions.find(
      (presc) => presc.ocr_request_event_id === id
    )
    return prescription
  }

  const selectedPresc = useMemo(
    () =>
      (selectedOcrRequestId && findPrescription(selectedOcrRequestId)) ||
      prescriptions[0],
    [selectedOcrRequestId, prescriptions]
  )

  return {
    selectedPresc,
    selectOcrRequest: (ocrRequestId: string) =>
      setSelectedOcrRequestId(ocrRequestId),
  }
}
