import { DisplayItemKey } from '../types/models/Presc'

type KeyValueType = {
  isPatientKanaEditable: boolean
  isInsuranceNumEditable: boolean
  isBirthdayEditable: boolean
  allowedInsuranceNumBlank: boolean
  isImageRotatable: boolean
  essentialDisplayItems: DisplayItemKey[]
  subDisplayItems: DisplayItemKey[]
  editableItemFontSize: string
  qr_size: string
  sample: number
  app_type: string
  is_annotator: boolean
  is_activated_photo: boolean
  pinedPharmacyId: string
  userId: string
  show_save_csv_button: boolean
}

type KeyType = keyof KeyValueType

export const localStorageWrapper = {
  setItem: <T extends KeyType>(key: T, item: KeyValueType[T]): void => {
    localStorage.setItem(key, JSON.stringify(item))
  },
  getItem: <T extends KeyType>(key: T): KeyValueType[T] => {
    const itemStr = localStorage.getItem(key)
    return itemStr === null ? null : JSON.parse(itemStr)
  },
}
