import { AxiosPromise } from 'axios'
import client from './client'
import {
  AiChangelog,
  FeatureRequest,
  Statistics,
} from '../types/models/Dashboard'

export const getStatistics = (): AxiosPromise<Statistics> => {
  return client.get('/dashboard/statistics/')
}

export const postFeatureRequest = (
  request: string
): AxiosPromise<FeatureRequest> => {
  return client.post('/dashboard/feature_requests/', { request })
}

export const getFeatureRequests = (): AxiosPromise<FeatureRequest[]> => {
  return client.get('/dashboard/feature_requests/')
}

export const getAiChangelogs = (): AxiosPromise<AiChangelog[]> => {
  return client.get('/dashboard/ai_improvement_reports/')
}
