import { Fab } from '@mui/material'
import { styled } from '@mui/system'

export const CustomFab = styled(Fab)(({ theme }) => ({
  right: 20,
  bottom: 20,
  position: 'fixed',
  color: '#fff',
  backgroundColor: theme.palette.primary.dark,
  zIndex: 1300,
  '&:hover': {
    backgroundColor: theme.palette.primary.translucent,
    color: '#fff',
  },
}))
