import { useEffect, useState } from 'react'
import { healthCheck } from '../apis/healthcheck'
import { MaintenanceModal } from '../components/templates/MaintenanceModal'

export const useShowMaintenanceModal = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    checkIsMaintenance()
    const intervalId = window.setInterval(checkIsMaintenance, 1000 * 10)
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const checkIsMaintenance = async () => {
    try {
      await healthCheck()
      setIsOpen(false)
    } catch (e) {
      setIsOpen(true)
      console.log('error')
    }
  }
  return <MaintenanceModal isOpen={isOpen} />
}
