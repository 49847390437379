import React, { useState, useCallback, FC } from 'react'
import { memo, NamedExoticComponent } from 'react'
import { Prescription } from '../types/models/Presc'
import { Box, Button, TextField } from '@mui/material'
import { MenuIcon } from '../icons/MenuIcon'
import { DrawerPrescList } from '../components/organisms/Drawer'
import { Pharmacy } from '../types/models/Pharmacy'
import { useSelectedPresc } from './useSelectedPresc'
import { useAnnotatePresc } from './useAnnotatePresc'
import { SERVICE_NAMES } from '../constants/const'

export const useAnnotatePrescDrawerButton = (selectedPharmacy: Pharmacy) => {
  const [isOpen, setIsOpen] = useState(false)
  const open = useCallback(() => setIsOpen(true), [])
  const close = useCallback(() => setIsOpen(false), [])

  const { prescriptions, loadAnnotatePrescList } = useAnnotatePresc(
    selectedPharmacy
  )
  const { selectedPresc, selectOcrRequest } = useSelectedPresc({
    prescriptions,
  })

  const AnnotatePrescDrawerButton = (
    <>
      <Box
        sx={{
          opacity: 0.8,
        }}
        position="fixed"
        zIndex={1300}
        left={0}
        top={0}
        bgcolor={'primary.dark'}
        color="#fff"
        p={1}
        borderRadius="0px 0px 8px 0px"
      >
        <Button
          onClick={open}
          startIcon={<MenuIcon />}
          style={{ color: '#fff' }}
        >
          {selectedPresc ? selectedPresc.label : SERVICE_NAMES.DEFAULT}
        </Button>
      </Box>
      <DrawerPrescList
        isOpen={isOpen}
        onClose={close}
        prescriptions={prescriptions}
        selectedPresc={selectedPresc}
        selectOcrRequest={selectOcrRequest}
        BottomButtons={[<></>]}
      />
    </>
  )

  return {
    AnnotatePrescDrawerButton,
    selectedPresc,
    loadAnnotatePrescList,
    selectOcrRequest,
    prescriptions,
  }
}
