import React, { FC, useState } from 'react'
import { Stack } from '@mui/material'
import { InputBox } from '../molecules/InputBox'
import { isKana } from '../../helpers/isHankaku'
import { CustomButton } from '../molecules/CustomButton'
import { EditInsuranceNumBox } from '../organisms/EditInsuranceNumBox'
import { localStorageWrapper } from '../../helpers/localStorage'

type Props = {
  insuranceNumberStr: string
  setInsuranceNumberStr: (firstName: string) => void
  completeEdit: () => void
  options: string[]
  initialVal: string
}

export const EditableInsuranceNumModalBody: FC<Props> = ({
  insuranceNumberStr,
  setInsuranceNumberStr,
  completeEdit,
  initialVal,
  options,
}) => {
  const isValidInsuranceNum = localStorageWrapper.getItem(
    'allowedInsuranceNumBlank'
  )
    ? [0, 6, 8].includes(insuranceNumberStr.length)
    : [6, 8].includes(insuranceNumberStr.length)

  const message = localStorageWrapper.getItem('allowedInsuranceNumBlank')
    ? '空欄のままか、6桁あるいは8桁で入力してください'
    : '6桁あるいは8桁で入力してください'

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '8px',
      }}
    >
      <Stack direction="row" spacing={1}>
        <EditInsuranceNumBox
          label="保険者番号"
          options={options}
          value={insuranceNumberStr}
          setValue={setInsuranceNumberStr}
          isInitialState={initialVal === insuranceNumberStr}
          inputType={'number'}
          isError={!isValidInsuranceNum}
          helperText={!isValidInsuranceNum ? message : ''}
          autoFocus={true}
        />
        <CustomButton
          sx={{
            color: '#3F51B5',
            bgcolor: '#E9F1F8',
            border: '2px solid ',
            borderColor: '3F51B5',
            height: '32px',
          }}
          onClick={completeEdit}
          disabled={!isValidInsuranceNum}
        >
          確定
        </CustomButton>
      </Stack>
    </div>
  )
}
