import React, { memo, NamedExoticComponent, useEffect, useState } from 'react'
import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import { Prescription } from '../../types/models/Presc'

type Props = {
  prescription: Prescription
  isLoadingNewQr: boolean
  openModal: () => void
}

export const QRImage: NamedExoticComponent<Props> = memo(
  ({ prescription, isLoadingNewQr, openModal }) => {
    const imageUrl = prescription.qr_images[0].image_url
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      if (imageUrl === null) return
      try {
        const image = new Image()
        setLoading(true)
        image.onload = () => {
          setLoading(false)
        }
        image.src = imageUrl
      } catch (e) {
        console.log(e)
        setLoading(false)
      } finally {
        setLoading(false)
      }
    }, [imageUrl])

    const theme = useTheme()
    return (
      <Box display="flex" justifyContent="right">
        {isLoadingNewQr || loading ? (
          <CircularProgress
            style={{
              width: '80%',
              height: '80%',
              color: theme.palette.secondary.dark,
              opacity: 0.3,
            }}
          />
        ) : imageUrl ? (
          <img
            src={imageUrl}
            style={{
              width: '80%',
              border: '1px solid rgba(0, 0, 0, 0.3)',
              borderRadius: '8px',
            }}
            onClick={openModal}
          />
        ) : (
          <Box
            width="80%"
            pt="40%"
            pb="40%"
            position="relative"
            bgcolor="rgba(0, 0, 0, 0.5)"
            color="#fff"
            style={{
              textAlign: 'center',
              alignItems: 'center',
              border: '1px solid rgba(0, 0, 0, 0.3)',
              zIndex: 1300,
            }}
          >
            <Typography
              style={{
                width: '100%',
              }}
            >
              医薬品数が多過ぎたためQRコードを生成できませんでした．
            </Typography>
          </Box>
        )}
      </Box>
    )
  }
)

QRImage.displayName = 'QRImage'
