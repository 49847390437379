import React, { FC, useState } from 'react'
import { Modal, Box, Typography } from '@mui/material'
import mamoruImage from '../atoms/mamoru_kun.jpg'

/* icons */
import { FavoriteIcon } from '../../icons/FavoriteIcon'

type Props = {
  open: boolean
}
export const RobotToast: FC<Props> = ({ open }) => {
  const messages = ['褒めてくれてありがとう！', 'これからもがんばるよ！']
  const [randomMessage, setRandomMessage] = useState<string>('')

  // Function to select a random message
  const getRandomMessage = () => {
    const randomIndex = Math.floor(Math.random() * messages.length)
    setRandomMessage(messages[randomIndex])
  }

  // Call getRandomMessage when the modal is opened
  React.useEffect(() => {
    if (open) {
      getRandomMessage()
    }
  }, [open])

  return (
    <Box
      sx={{
        bgcolor: 'rgba(0, 0, 0, 0)',
        alignContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {open && (
        <>
          <img
            src={mamoruImage}
            alt="Robot"
            style={{
              width: 40,
              display: 'block',
              margin: 'auto',
            }}
          />
          <Typography
            gutterBottom
            align="center"
            style={{ fontSize: '60%', fontWeight: 'bold' }}
          >
            {randomMessage}
          </Typography>
        </>
      )}
    </Box>
  )
}
