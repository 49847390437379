import React, { FC } from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import catImage from '../atoms/cat.png'
import medilabLogo from '../atoms/medilab.png'
import tmnImage from '../atoms/tmnImage.png'
import { Copyright } from '../molecules/Copyright'
import { Stack, useTheme } from '@mui/material'
import { Typography } from '@material-ui/core'
import { localStorageWrapper } from '../../helpers/localStorage'
import { useGetAppType } from '../../hooks/useGetAppType'

type Props = {
  isOpen: boolean
}

export const MaintenanceModal: FC<Props> = ({ isOpen }) => {
  const appType = useGetAppType()
  const logo = appType === 'Medilab' ? medilabLogo : catImage
  const theme = useTheme()
  const body = (
    <Stack
      height="100%"
      width="100%"
      sx={{
        backgroundColor: '#fff',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack spacing={3} alignItems="center">
        {appType === 'Medilab' && (
          <Box pt={2}>
            <img src={logo} />
          </Box>
        )}
        {appType === 'Medilab' ? (
          <img
            src={catImage}
            style={{
              height: '250px',
              width: '200px',
            }}
          />
        ) : (
          <img
            src={tmnImage}
            style={{
              height: '250px',
              width: '250px',
            }}
          />
        )}
        <Typography
          variant="h5"
          style={{
            color: theme.palette.primary.dark,
            fontFamily: 'Hiragino Maru Gothic Pro',
          }}
        >
          現在アクセスができない状況です
        </Typography>
        <Box textAlign="center">
          <Typography
            style={{
              color: theme.palette.secondary.dark,
            }}
          >
            数分後にアクセスし直してください。
          </Typography>
          <Typography
            style={{
              color: theme.palette.secondary.dark,
            }}
          >
            数分後にアクセスし直してもこのページが表示される場合は担当者にお問合せください。
          </Typography>
        </Box>
        <Box>
          <Copyright />
        </Box>
      </Stack>
    </Stack>
  )

  return (
    <Modal style={{ zIndex: 1301 }} open={isOpen}>
      {body}
    </Modal>
  )
}
