import { useEffect, useMemo } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { getPharmacies } from '../apis/pharmacy'
import { GlobalState } from '../stores/reducer'
import { Pharmacy } from '../types/models/Pharmacy'
import { localStorageWrapper } from '../helpers/localStorage'

export const usePharmacy = () => {
  const [pharmacies, setPharmacies] = useState<Pharmacy[]>([])
  const isLoggedIn = useSelector((state: GlobalState) => state.isLoggedIn)

  useEffect(() => {
    if (!isLoggedIn) return
    loadPharmacies()
  }, [isLoggedIn])

  const loadPharmacies = async () => {
    try {
      const { data } = await getPharmacies()
      console.log('pharmacies', data)
      setPharmacies(data)
    } catch (e) {
      console.log(e)
    }
  }

  const [selectedPharmacyId, setSelectedPharmacyId] = useState<Pharmacy['id']>(
    localStorageWrapper.getItem('pinedPharmacyId')
  )

  const findPrescription = (id: Pharmacy['id']) => {
    const pharmacy = pharmacies.find((ph) => ph.id === id)
    return pharmacy
  }

  const selectedPharmacy = useMemo(
    () =>
      (selectedPharmacyId && findPrescription(selectedPharmacyId)) ||
      pharmacies[0],
    [selectedPharmacyId, pharmacies]
  )

  return {
    pharmacies,
    selectedPharmacy,
    selectPharmacy: (selectedPharmacyId: string) => {
      setSelectedPharmacyId(selectedPharmacyId)
      localStorageWrapper.setItem('pinedPharmacyId', selectedPharmacyId)
    },
  }
}
