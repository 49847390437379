import React from 'react'
import { Box, Typography } from '@mui/material'

export const ErrordOverlay = (
  <Box
    position="absolute"
    bgcolor="rgba(0, 0, 0, 0.5)"
    color="#fff"
    style={{
      inset: 0,
      margin: 'auto',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      zIndex: 1300,
    }}
  >
    <Typography
      style={{
        width: '100%',
        height: '32px',
      }}
    >
      AI読み取りに失敗しました <br></br>
      今後読み取り可能となるよう、AIが学習致します
    </Typography>
  </Box>
)
