import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { EditableKanaModalBody } from '../components/templates/EditableKanaModalBody'
import { divideNameIntoFirstAndLast } from '../helpers/divideNameIntoFirstAndLast'
import { BasicResult, EditBasicResultKey } from '../types/models/Presc'
import { useModal } from './useModal'
import Amplitude from '../utils/analytics/amplitude'

export const useKanaEditModal = (
  prescId: string,
  basicResult: BasicResult,
  editBasicResult: (key: EditBasicResultKey) => void
) => {
  const [firstName, setFirstName] = useState(
    divideNameIntoFirstAndLast(
      basicResult.id_11_patient_name_kana.selected_option
    ).first
  )

  const [lastName, setLastName] = useState(
    divideNameIntoFirstAndLast(
      basicResult.id_11_patient_name_kana.selected_option
    ).last
  )

  useEffect(() => {
    setFirstName(
      divideNameIntoFirstAndLast(
        basicResult.id_11_patient_name_kana.selected_option
      ).first
    )
    setLastName(
      divideNameIntoFirstAndLast(
        basicResult.id_11_patient_name_kana.selected_option
      ).last
    )
  }, [prescId])

  const firstNameList = Array.from(
    new Set(
      basicResult.id_11_patient_name_kana.options.map(
        (name) => divideNameIntoFirstAndLast(name).first
      )
    )
  )

  const lastNameList = Array.from(
    new Set(
      basicResult.id_11_patient_name_kana.options.map(
        (name) => divideNameIntoFirstAndLast(name).last
      )
    )
  )

  const completeEdit = useCallback(() => {
    const nameBeforeEdit = basicResult.id_11_patient_name_kana.selected_option
    editBasicResult({
      key: 'id_11_patient_name_kana',
      value: [firstName, lastName].join(' '),
    })
    closeEditableModal()
    Amplitude.logEvent({
      event: 'editKana',
      properties: {
        before: nameBeforeEdit,
        after: [firstName, lastName].join(' '),
      },
    })
  }, [firstName, lastName])

  const {
    openModal: openEditableModal,
    closeModal: closeEditableModal,
    renderModal: renderEditableModal,
  } = useModal(
    <EditableKanaModalBody
      firstNameList={firstNameList}
      lastNameList={lastNameList}
      initialFirstName={
        divideNameIntoFirstAndLast(
          basicResult.id_11_patient_name_kana.selected_option
        ).first
      }
      firstName={firstName}
      initialLastName={
        divideNameIntoFirstAndLast(
          basicResult.id_11_patient_name_kana.selected_option
        ).last
      }
      lastName={lastName}
      setFirstName={setFirstName}
      setLastName={setLastName}
      completeEdit={completeEdit}
    />,
    'right'
  )

  return { openEditableModal, renderEditableModal }
}
