import React, { useEffect, useState } from 'react'
import { MedicalConfirmationModal } from '../components/templates/MedicalConfirmationModal'
import {
  GigiAlert,
  LowAccuracyAlert,
  ReshapeReport,
} from '../types/models/Presc'
import { CheckListIcon } from '../icons/CheckListIcon'
import { MedChangeAlert } from '../types/models/MedChangeLog'
import { CustomFab } from '../components/molecules/CustomFab'

export const useMedicationAlert = (
  id: string | undefined,
  low_accuracy_alerts: LowAccuracyAlert[] | undefined,
  reshape_reports: ReshapeReport[] | undefined,
  gigi_alerts: GigiAlert[] | undefined,
  med_change_alerts: MedChangeAlert[] | undefined
) => {
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    if (
      !low_accuracy_alerts &&
      !gigi_alerts &&
      !reshape_reports &&
      !med_change_alerts
    )
      return
    const alertExists =
      !!low_accuracy_alerts?.length ||
      !!gigi_alerts?.length ||
      !!med_change_alerts?.length ||
      !!reshape_reports?.length
    setIsOpen(alertExists ? true : false)
  }, [id])

  const MedicationModal = (
    <MedicalConfirmationModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      gigi_alerts={gigi_alerts}
      reshape_reports={reshape_reports}
      low_accuracy_alerts={low_accuracy_alerts}
      med_change_alerts={med_change_alerts}
    />
  )

  const onSetIsOpen = (value: boolean) => {
    setIsOpen(value)
  }

  return {
    MedicationModal,
    onSetIsOpen,
  }
}

export const openButton = (onClick: () => void) => {
  return (
    <CustomFab onClick={onClick}>
      <CheckListIcon />
    </CustomFab>
  )
}
