type KanaDict = {
  [key: string]: string
}

const HankakuKanaList = [
  'ｦ',
  'ｧ',
  'ｨ',
  'ｩ',
  'ｪ',
  'ｫ',
  'ｬ',
  'ｭ',
  'ｮ',
  'ｯ',
  'ｰ',
  'ｱ',
  'ｲ',
  'ｳ',
  'ｴ',
  'ｵ',
  'ｶ',
  'ｷ',
  'ｸ',
  'ｹ',
  'ｺ',
  'ｻ',
  'ｼ',
  'ｽ',
  'ｾ',
  'ｿ',
  'ﾀ',
  'ﾁ',
  'ﾂ',
  'ﾃ',
  'ﾄ',
  'ﾅ',
  'ﾆ',
  'ﾇ',
  'ﾈ',
  'ﾉ',
  'ﾊ',
  'ﾋ',
  'ﾌ',
  'ﾍ',
  'ﾎ',
  'ﾏ',
  'ﾐ',
  'ﾑ',
  'ﾒ',
  'ﾓ',
  'ﾔ',
  'ﾕ',
  'ﾖ',
  'ﾗ',
  'ﾘ',
  'ﾙ',
  'ﾚ',
  'ﾛ',
  'ﾜ',
  'ﾝ',
  ' ',
  'ﾞ',
  'ﾟ',
]

const hiraganaDict = {
  が: 'ｶﾞ',
  ぎ: 'ｷﾞ',
  ぐ: 'ｸﾞ',
  げ: 'ｹﾞ',
  ご: 'ｺﾞ',
  ざ: 'ｻﾞ',
  じ: 'ｼﾞ',
  ず: 'ｽﾞ',
  ぜ: 'ｾﾞ',
  ぞ: 'ｿﾞ',
  だ: 'ﾀﾞ',
  ぢ: 'ﾁﾞ',
  づ: 'ﾂﾞ',
  で: 'ﾃﾞ',
  ど: 'ﾄﾞ',
  ば: 'ﾊﾞ',
  び: 'ﾋﾞ',
  ぶ: 'ﾌﾞ',
  べ: 'ﾍﾞ',
  ぼ: 'ﾎﾞ',
  ゔ: 'ｳﾞ',
  ぱ: 'ﾊﾟ',
  ぴ: 'ﾋﾟ',
  ぷ: 'ﾌﾟ',
  ぺ: 'ﾍﾟ',
  ぽ: 'ﾎﾟ',
  '・': '･',
  を: 'ｦ',
  ぁ: 'ｧ',
  ぃ: 'ｨ',
  ぅ: 'ｩ',
  ぇ: 'ｪ',
  ぉ: 'ｫ',
  ゃ: 'ｬ',
  ゅ: 'ｭ',
  ょ: 'ｮ',
  っ: 'ｯ',
  ー: 'ｰ',
  あ: 'ｱ',
  い: 'ｲ',
  う: 'ｳ',
  え: 'ｴ',
  お: 'ｵ',
  か: 'ｶ',
  き: 'ｷ',
  く: 'ｸ',
  け: 'ｹ',
  こ: 'ｺ',
  さ: 'ｻ',
  し: 'ｼ',
  す: 'ｽ',
  せ: 'ｾ',
  そ: 'ｿ',
  た: 'ﾀ',
  ち: 'ﾁ',
  つ: 'ﾂ',
  て: 'ﾃ',
  と: 'ﾄ',
  な: 'ﾅ',
  に: 'ﾆ',
  ぬ: 'ﾇ',
  ね: 'ﾈ',
  の: 'ﾉ',
  は: 'ﾊ',
  ひ: 'ﾋ',
  ふ: 'ﾌ',
  へ: 'ﾍ',
  ほ: 'ﾎ',
  ま: 'ﾏ',
  み: 'ﾐ',
  む: 'ﾑ',
  め: 'ﾒ',
  も: 'ﾓ',
  や: 'ﾔ',
  ゆ: 'ﾕ',
  よ: 'ﾖ',
  ら: 'ﾗ',
  り: 'ﾘ',
  る: 'ﾙ',
  れ: 'ﾚ',
  ろ: 'ﾛ',
  わ: 'ﾜ',
  ゐ: 'ｲ',
  ゑ: 'ｴ',
  ん: 'ﾝ',
} as KanaDict

const katakanaDict = {
  ア: 'ｱ',
  イ: 'ｲ',
  ウ: 'ｳ',
  エ: 'ｴ',
  オ: 'ｵ',
  カ: 'ｶ',
  キ: 'ｷ',
  ク: 'ｸ',
  ケ: 'ｹ',
  コ: 'ｺ',
  サ: 'ｻ',
  シ: 'ｼ',
  ス: 'ｽ',
  セ: 'ｾ',
  ソ: 'ｿ',
  タ: 'ﾀ',
  チ: 'ﾁ',
  ツ: 'ﾂ',
  テ: 'ﾃ',
  ト: 'ﾄ',
  ナ: 'ﾅ',
  ニ: 'ﾆ',
  ヌ: 'ﾇ',
  ネ: 'ﾈ',
  ノ: 'ﾉ',
  ハ: 'ﾊ',
  ヒ: 'ﾋ',
  フ: 'ﾌ',
  ヘ: 'ﾍ',
  ホ: 'ﾎ',
  マ: 'ﾏ',
  ミ: 'ﾐ',
  ム: 'ﾑ',
  メ: 'ﾒ',
  モ: 'ﾓ',
  ヤ: 'ﾔ',
  ユ: 'ﾕ',
  ヨ: 'ﾖ',
  ラ: 'ﾗ',
  リ: 'ﾘ',
  ル: 'ﾙ',
  レ: 'ﾚ',
  ロ: 'ﾛ',
  ワ: 'ﾜ',
  ヰ: 'ｲ',
  ゑ: 'ｴ',
  ヲ: 'ｦ',
  ン: 'ﾝ',
  ガ: 'ｶﾞ',
  ギ: 'ｷﾞ',
  グ: 'ｸﾞ',
  ゲ: 'ｹﾞ',
  ゴ: 'ｺﾞ',
  ザ: 'ｻﾞ',
  ジ: 'ｼﾞ',
  ズ: 'ｽﾞ',
  ゼ: 'ｾﾞ',
  ゾ: 'ｿﾞ',
  ダ: 'ﾀﾞ',
  ヂ: 'ﾁﾞ',
  ヅ: 'ﾂﾞ',
  デ: 'ﾃﾞ',
  ド: 'ﾄﾞ',
  バ: 'ﾊﾞ',
  ビ: 'ﾋﾞ',
  ブ: 'ﾌﾞ',
  ベ: 'ﾍﾞ',
  ボ: 'ﾎﾞ',
  パ: 'ﾊﾟ',
  ピ: 'ﾋﾟ',
  プ: 'ﾌﾟ',
  ペ: 'ﾍﾟ',
  ポ: 'ﾎﾟ',
  ァ: 'ｧ',
  ィ: 'ｨ',
  ゥ: 'ｩ',
  ェ: 'ｪ',
  ォ: 'ｫ',
  ャ: 'ｬ',
  ュ: 'ｭ',
  ョ: 'ｮ',
  ッ: 'ｯ',
  ー: 'ｰ',
  '・': '･',
} as KanaDict

const isHiraganaChar = (char: string) => char in hiraganaDict
const isKatakanaChar = (char: string) => char in katakanaDict
const isHankakuKatakanaChar = (char: string) => HankakuKanaList.includes(char)

export const isKana = (inputStr: string) => {
  const strList = inputStr.split('')
  return strList.every(
    (str) =>
      isHiraganaChar(str) || isKatakanaChar(str) || isHankakuKatakanaChar(str)
  )
}

export const isHankakuKana = (inputStr: string) => {
  const strList = inputStr.split('')
  return strList.every((str) => HankakuKanaList.includes(str))
}

export const changeIntoHankakuKana = (inputStr: string) => {
  const strList = inputStr.split('')
  const hankakuStrList = strList.map((element) =>
    element in hiraganaDict
      ? hiraganaDict[element]
      : element in katakanaDict
      ? katakanaDict[element]
      : element
  )
  return hankakuStrList.join('')
}
