type Action = {
  type: string
  isLoggedIn: boolean
  appType: string
}

type SetIsLoggedIn = {
  type: string
  isLoggedIn: boolean
  appType: string
}

export type GlobalState = {
  isLoggedIn: boolean
  appType: string
}

const initialState: GlobalState = {
  isLoggedIn: false,
  appType: 'Medilab',
}

export const setIsLoggedIn = (
  isLoggedIn: boolean,
  appType: string
): SetIsLoggedIn => ({
  type: 'setIsLoggedIn',
  isLoggedIn,
  appType,
})

export const reducer = (state = initialState, action: Action): GlobalState => {
  switch (action.type) {
    case 'setIsLoggedIn':
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
        appType: action.appType,
      }
    default:
      return state
  }
}
