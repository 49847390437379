import React, { CSSProperties, FC } from 'react'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { Typography, useTheme } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { localStorageWrapper } from '../../helpers/localStorage'
import { editableItemFontSize } from '../../constants/const'

type Props = {
  label?: string
  isReported?: boolean
  selectedChoice: string
  choices: string[]
  onChangeChoice: (choice: string) => void
  maxWidth?: CSSProperties['maxWidth']
  hasHighCheckPriority: boolean
  openEditModal?: () => void
  error?: boolean
}

export const SelectBox: FC<Props> = ({
  label,
  isReported,
  selectedChoice,
  choices,
  onChangeChoice,
  maxWidth,
  hasHighCheckPriority,
  openEditModal,
  error,
}) => {
  const theme = useTheme()
  return (
    <FormControl fullWidth variant="outlined" error={error}>
      <InputLabel
        sx={{
          color: isReported
            ? theme.palette.primary.dark
            : theme.palette.secondary.main,
          '&.Mui-focused': {
            color: isReported
              ? theme.palette.primary.dark
              : theme.palette.secondary.main,
          },
        }}
        style={selectedChoice ? {} : { transform: 'translate(14px, 8px)' }}
      >
        {label}
      </InputLabel>
      <Select
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: isReported
              ? theme.palette.primary.dark
              : theme.palette.secondary.main,
            borderWidth: isReported ? '2px' : '1px',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: isReported
              ? theme.palette.primary.dark
              : theme.palette.secondary.main,
            borderWidth: isReported ? '2px' : '1px',
          },
        }}
        label={label}
        style={{
          textAlign: 'left',
          maxWidth,
          backgroundColor: hasHighCheckPriority
            ? theme.palette.warning.light
            : '#fff',
          height: '32px',
          borderRadius: 0,
          width: '100%',
          fontSize:
            label &&
            ['患者名カナ', '西暦', '元号', '和暦', '月', '日'].includes(label)
              ? editableItemFontSize
              : '1rem',
        }}
        value={selectedChoice}
        onChange={(e) =>
          e.target.value === '入力する'
            ? openEditModal?.()
            : onChangeChoice(e.target.value as string)
        }
        endAdornment={
          isReported && (
            <Typography
              sx={{ fontWeight: 'bold', color: theme.palette.primary.dark }}
            >
              ！
            </Typography>
          )
        }
        IconComponent={
          isReported
            ? () => null
            : () => <ArrowDropDownIcon sx={{ paddingRight: '8px' }} />
        }
      >
        {choices.map((choice) => (
          <MenuItem
            dense
            key={choice}
            value={choice}
            sx={{
              borderSpacing: 0,
              fontSize:
                label &&
                ['患者名カナ', '西暦', '元号', '和暦', '月', '日'].includes(
                  label
                )
                  ? editableItemFontSize
                  : '1rem',
            }}
          >
            {choice}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
