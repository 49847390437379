import * as React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'

type Props = {
  label: string
  date: Dayjs | null
  onChange: (date: Dayjs | null) => void
}

export const DatePicker: React.FC<Props> = ({ label, date, onChange }) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <MuiDatePicker
      sx={{ width: '100%' }}
      label={label}
      value={date ? dayjs(date) : null}
      onChange={onChange}
    />
  </LocalizationProvider>
)
