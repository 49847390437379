import client from './client'
import { AxiosPromise } from 'axios'

export const postRequestMessage = (
  prescId: string,
  text: string
): AxiosPromise<string> => {
  const params = { text }
  return client.post(`/prescriptions/${prescId}/request_messages/`, params, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
