import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { BasicResult, EditBasicResultKey } from '../types/models/Presc'
import { useModal } from './useModal'
import Amplitude from '../utils/analytics/amplitude'
import { localStorageWrapper } from '../helpers/localStorage'
import { BirthdaySelectModalBody } from '../components/templates/BirthdaySelectModalBody'

export const useBirthdaySelectModal = (
  prescId: string,
  basicResult: BasicResult,
  editBasicResult: (key: EditBasicResultKey) => void
) => {
  const [birthday, setBirthday] = useState(
    basicResult['id_13_patient_birthday']['selected_option']
  )
  useEffect(() => {
    setBirthday(basicResult['id_13_patient_birthday']['selected_option'])
  }, [prescId])

  const completeEdit = () => {
    const birthdayBeforeEdit =
      basicResult.id_13_patient_birthday.selected_option
    editBasicResult({
      key: 'id_13_patient_birthday',
      value: birthday,
    })
    closeEditableModal()
    Amplitude.logEvent({
      event: 'editBirthday',
      properties: {
        before: birthdayBeforeEdit,
        after: birthday,
      },
    })
  }

  const {
    openModal: openEditableModal,
    closeModal: closeEditableModal,
    renderModal: renderEditableModal,
  } = useModal(
    <BirthdaySelectModalBody
      completeEdit={completeEdit}
      birthday={birthday}
      setBirthday={setBirthday}
    />,
    'right'
  )

  return { openEditableModal, renderEditableModal }
}
