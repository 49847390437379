import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { checkAuth } from '../apis/auth'
import { GlobalState } from '../stores/reducer'

export const useCheckAuth = () => {
  const isLoggedIn = useSelector((state: GlobalState) => state.isLoggedIn)
  useEffect(() => {
    if (!isLoggedIn) return
    const intervalId = window.setInterval(checkAuth, 1000 * 60 * 60 * 24) //署名付きCookieの期限切れ防止のため1日ごとに認証をチェックする(署名付きCookieはcheckAuthにおいて付与される)
    return () => {
      clearInterval(intervalId)
    }
  }, [isLoggedIn])
}
