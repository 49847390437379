import React, { FC } from 'react'

import { Stack, Box, Modal, Typography, useTheme } from '@mui/material'
import GppGoodRoundedIcon from '@mui/icons-material/GppGoodRounded'
import {
  LowAccuracyAlert,
  GigiAlert,
  ReshapeReport,
} from '../../types/models/Presc'
import {
  GigiAlertTypography,
  ReshapeReportTypography,
  AccuracyAlertTypography,
  MedChangeTypography,
} from '../molecules/HighlightedTypography'
import { MedChangeAlert } from '../../types/models/MedChangeLog'
import { CustomButton } from '../molecules/CustomButton'
import { localStorageWrapper } from '../../helpers/localStorage'
import { SHORT_SERVICE_NAMES } from '../../constants/const'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  low_accuracy_alerts: LowAccuracyAlert[] | undefined
  reshape_reports: ReshapeReport[] | undefined
  gigi_alerts: GigiAlert[] | undefined
  med_change_alerts: MedChangeAlert[] | undefined
}

export const MedicalConfirmationModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  reshape_reports,
  low_accuracy_alerts,
  gigi_alerts,
  med_change_alerts,
}) => {
  const hasAccuracyAlerts = !!low_accuracy_alerts?.length
  const hasGigiAlerts = !!gigi_alerts?.length
  const hasMedChangeAlerts = !!med_change_alerts?.length
  const hasReshapeReports = !!reshape_reports?.length
  const hasAICheckResults =
    hasGigiAlerts || hasMedChangeAlerts || hasReshapeReports
  const company = localStorageWrapper.getItem('app_type') || 'Medilab'
  const shortServiceName =
    company === 'TMN' ? SHORT_SERVICE_NAMES.TMN : SHORT_SERVICE_NAMES.DEFAULT

  const theme = useTheme()
  return (
    <Modal open={isOpen} style={{ margin: '48px' }}>
      <Stack
        sx={{
          [theme.breakpoints.down('md')]: {
            width: '100%',
            transform: 'translate(-50%,-50%)',
            top: '50%',
          },
          marginLeft: '16px',
          marginRight: '16px',
          alignContent: 'flex-start',
        }}
        position="absolute"
        direction="column"
        top="0%"
        left="50%"
        overflow="scroll"
        width="50%"
        height="500px"
        borderRadius="8px"
        p={1}
        style={{
          backgroundColor: 'rgba(255, 255, 255, 1)',
        }}
      >
        {/* コンテンツ全体を囲むボックス */}
        <Stack flex="1 1 auto" pt={4}>
          {/* AI精度診断セクション */}
          {hasAccuracyAlerts && (
            <Stack pb={4}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  pl: '24px',
                  pb: '8px',
                }}
              >
                {'✔︎ ' + shortServiceName + ' 精度診断'}
              </Typography>
              <Stack pl={4} pr={4}>
                {low_accuracy_alerts?.map((item) => (
                  <Stack key={item.id} pb={1}>
                    <AccuracyAlertTypography
                      text={item.alert_text}
                      highlightWord={item.target}
                    />
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )}

          {/* AIチェック結果セクション */}
          {hasAICheckResults && (
            <Stack pb={4}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  pl: '24px',
                  pb: '8px',
                }}
              >
                {'✔︎ ' + shortServiceName + 'からのお知らせ'}
              </Typography>
              <Stack pl={4} pr={4}>
                {hasGigiAlerts &&
                  gigi_alerts?.map((item) => (
                    <Stack key={item.id} pb={1}>
                      <GigiAlertTypography
                        text={item.alert_text}
                        medNames={item.medicine_names}
                      />
                    </Stack>
                  ))}
                {hasMedChangeAlerts &&
                  med_change_alerts?.map((item) => (
                    <Stack key={item.id} pb={1}>
                      <MedChangeTypography
                        text={item.text}
                        medName={item.original_med_name}
                      />
                    </Stack>
                  ))}
                {hasReshapeReports &&
                  reshape_reports?.map((item) => (
                    <Stack key={item.id} pb={1}>
                      <ReshapeReportTypography
                        text={item.report_text}
                        highlightWords={item.targets}
                      />
                    </Stack>
                  ))}
              </Stack>
            </Stack>
          )}

          {/* 問題なしの場合の表示 */}
          {!hasAccuracyAlerts && !hasAICheckResults && (
            <Stack pt={4} pb={4} alignItems="center">
              <Typography
                sx={{
                  fontWeight: 'bold',
                  pl: '24px',
                  pb: '32px',
                }}
              ></Typography>
              <GppGoodRoundedIcon style={{ fontSize: 120 }} />
              <Typography
                sx={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: 16,
                  pt: '32px',
                  pb: '40px',
                }}
              >
                {shortServiceName + 'からのお知らせはありません。'}
              </Typography>
            </Stack>
          )}
        </Stack>

        {/* 閉じるボタン */}
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '10%' }}
        >
          <CustomButton
            onClick={() => setIsOpen(false)}
            variant="contained"
            size="large"
            style={{ width: '110px' }}
          >
            確認完了
          </CustomButton>
        </Stack>
      </Stack>
    </Modal>
  )
}
