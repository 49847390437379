import React, {
  FC,
  useState,
  useRef,
  useEffect,
  FocusEventHandler,
} from 'react'
import { TextField, Box, List, ListItem } from '@material-ui/core'
import { Stack, styled } from '@mui/material'
import { localStorageWrapper } from '../../helpers/localStorage'
import { editableItemFontSize } from '../../constants/const'
import { changeIntoHankakuKana } from '../../helpers/isHankaku'

type Props = {
  label: string
  options: string[]
  value: string | null
  setValue: (v: string) => void
  isInitialState: boolean
  inputType?: 'text' | 'number'
  isError?: boolean
  helperText?: string
  autoFocus?: boolean
  onBlur?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined
}

const StyledInputBox = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    height: '32px',
    borderRadius: '0',
    '& input': {
      fontSize: editableItemFontSize,
    },
  },
  width: '100%',
})

export const EditNameBox: FC<Props> = ({
  label,
  options,
  value,
  setValue,
  inputType,
  isError,
  helperText,
  isInitialState,
  autoFocus,
  onBlur,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const filteredOptions = isInitialState
    ? options
    : options.filter((option) => {
        return option.startsWith(changeIntoHankakuKana(value || '') || '')
      })
  const limitedOptions = filteredOptions.slice(0, 5)

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        containerRef.current &&
        e.target instanceof Node &&
        !containerRef.current.contains(e.target)
      ) {
        setIsOpen(false)
      }
    }
    autoFocus && setIsOpen(true)
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
    <Box position="relative" width="100%" {...{ ref: containerRef }}>
      <StyledInputBox
        fullWidth
        variant="outlined"
        label={label}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onClick={(e) => {
          e.stopPropagation()
          if (!isOpen) {
            setIsOpen(true)
          }
        }}
        style={{ cursor: 'pointer' }}
        type={inputType}
        error={isError}
        helperText={helperText}
        autoFocus={autoFocus}
        onBlur={onBlur}
      />
      {isOpen && (
        <Box
          style={{
            position: 'absolute',
            width: '100%',
            maxHeight: '200px',
            overflowY: 'auto',
            background: '#fff',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
            zIndex: 1500,
          }}
        >
          <List>
            {limitedOptions.map((option, index) => (
              <ListItem
                dense
                style={{
                  fontSize: editableItemFontSize,
                }}
                key={index}
                onClick={() => {
                  setValue(option)
                  setIsOpen(false)
                }}
              >
                {option}
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  )
}
