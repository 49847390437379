import SearchIcon from '@mui/icons-material/Search'
import { Box, Button, TextField } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { FC, useState, KeyboardEvent } from 'react'

type Props = {
  onClick: (searchTerm: string) => void
  options: string[]
}

export const SearchBar: FC<Props> = ({ onClick, options }) => {
  const [searchTerm, setSearchTerm] = useState('')

  const isValidate = options.includes(searchTerm)

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && isValidate) {
      event.preventDefault() // フォームの送信を防ぐ
      onClick(searchTerm)
    }
  }

  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        variant="outlined"
        placeholder="OCRRequestEventのIDを入力..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ width: '100%', zIndex: 1350, backgroundColor: '#fff' }}
        helperText={
          !isValidate && searchTerm !== '' ? '一致する処方箋がありません' : ''
        }
        error={!isValidate && searchTerm !== ''}
        onKeyPress={handleKeyPress}
      />
      <Button
        variant="contained"
        type="button"
        style={{ zIndex: 1350, height: '100%' }}
        onClick={() => onClick(searchTerm)}
        disabled={!isValidate}
      >
        検索
      </Button>
    </Box>
  )
}
