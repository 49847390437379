import React, { FC, useEffect, useState } from 'react'
import { Stack, Switch, Typography } from '@mui/material'
import { InputBox } from '../molecules/InputBox'
import { isKana } from '../../helpers/isHankaku'
import { CustomButton } from '../molecules/CustomButton'
import { EditInsuranceNumBox } from '../organisms/EditInsuranceNumBox'
import { localStorageWrapper } from '../../helpers/localStorage'
import { SelectBox } from '../molecules/SelectBox'
import {
  DAYS,
  MONTHS,
  YEARS,
  generateYearList,
  isJapaneseDate,
  parseDate,
} from '../../constants/const'

type Props = {
  completeEdit: () => void
  birthday: string
  setBirthday: (birthday: string) => void
}

export const BirthdaySelectModalBody: FC<Props> = ({
  completeEdit,
  setBirthday,
  birthday,
}) => {
  const [isSeireki, setIsSeireki] = useState(parseDate(birthday).isSeireki)
  const [gregorianYear, setGregorianYear] = useState(
    parseDate(birthday).gregorianYear
  )
  const [gengou, setGengou] = useState(parseDate(birthday).gengou)
  const [wareki, setWareki] = useState(parseDate(birthday).wareki)
  const [month, setMonth] = useState(parseDate(birthday).month)
  const [day, setDay] = useState(parseDate(birthday).day)

  const onSeirekiChange = (newIsSeireki: boolean) => {
    setIsSeireki(newIsSeireki)
    const newBirthdayStr = newIsSeireki
      ? `${gregorianYear}${month}${day}`
      : `${gengou}${wareki}${month}${day}`
    setBirthday(newBirthdayStr)
  }

  const onGengouChange = (newGengou: string) => {
    setGengou(newGengou)
    const newBirthdayStr = `${newGengou}${wareki}${month}${day}`
    setBirthday(newBirthdayStr)
    setGregorianYear(parseDate(newBirthdayStr).gregorianYear)
  }

  const onWarekiChange = (newWareki: string) => {
    setWareki(newWareki)
    const newBirthdayStr = `${gengou}${newWareki}${month}${day}`
    setBirthday(newBirthdayStr)
    setGregorianYear(parseDate(newBirthdayStr).gregorianYear)
  }

  const onGregorianYearChange = (newGregorianYear: string) => {
    setGregorianYear(newGregorianYear)
    const newBirthdayStr = `${newGregorianYear}${month}${day}`
    setBirthday(newBirthdayStr)
    setWareki(parseDate(newBirthdayStr).wareki)
    setGengou(parseDate(newBirthdayStr).gengou)
  }

  const onMonthChange = (newMonth: string) => {
    setMonth(newMonth)
    const newBirthdayStr = isSeireki
      ? `${gregorianYear}${newMonth}${day}`
      : `${gengou}${wareki}${newMonth}${day}`
    setBirthday(newBirthdayStr)
  }

  const onDayChange = (newDay: string) => {
    setDay(newDay)
    const newBirthdayStr = isSeireki
      ? `${gregorianYear}${month}${newDay}`
      : `${gengou}${wareki}${month}${newDay}`
    setBirthday(newBirthdayStr)
  }

  const isDisabled =
    !YEARS.includes(gregorianYear) ||
    !['明治', '大正', '昭和', '平成', '令和'].includes(gengou) ||
    !generateYearList(gengou).includes(wareki) ||
    !MONTHS.includes(month) ||
    !(DAYS[month] ?? []).includes(day)

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '8px',
      }}
    >
      <Stack
        direction="column"
        spacing={3}
        alignItems={'center'}
        sx={{ width: '100%', p: 2 }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>和暦</Typography>
          <Switch
            checked={isSeireki}
            onChange={() => onSeirekiChange(!isSeireki)}
            inputProps={{ 'aria-label': 'ant design' }}
          />
          <Typography>西暦</Typography>
        </Stack>
        {isSeireki ? (
          <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
            <SelectBox
              label="西暦"
              selectedChoice={gregorianYear}
              choices={YEARS}
              onChangeChoice={(value: string) => onGregorianYearChange(value)}
              hasHighCheckPriority={false}
              error={!YEARS.includes(gregorianYear)}
            />
          </Stack>
        ) : (
          <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
            <SelectBox
              label="元号"
              selectedChoice={gengou}
              choices={['明治', '大正', '昭和', '平成', '令和']}
              onChangeChoice={(value: string) => onGengouChange(value)}
              hasHighCheckPriority={false}
              error={!['明治', '大正', '昭和', '平成', '令和'].includes(gengou)}
            />
            <SelectBox
              label="和暦"
              selectedChoice={wareki}
              choices={generateYearList(gengou)}
              onChangeChoice={(value: string) => onWarekiChange(value)}
              hasHighCheckPriority={false}
              error={!generateYearList(gengou).includes(wareki)}
            />
          </Stack>
        )}
        <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
          <SelectBox
            label="月"
            selectedChoice={month}
            choices={MONTHS}
            onChangeChoice={(value: string) => onMonthChange(value)}
            hasHighCheckPriority={false}
            error={!MONTHS.includes(month)}
          />

          <SelectBox
            label="日"
            selectedChoice={day}
            choices={DAYS[month] ?? []}
            onChangeChoice={(value: string) => onDayChange(value)}
            hasHighCheckPriority={false}
            error={!(DAYS[month] ?? []).includes(day)}
          />
          <CustomButton
            sx={{
              color: '#3F51B5',
              bgcolor: '#E9F1F8',
              border: '2px solid ',
              borderColor: '3F51B5',
              height: '32px',
            }}
            onClick={completeEdit}
            disabled={isDisabled}
          >
            確定
          </CustomButton>
        </Stack>
        {isDisabled && (
          <Typography fontSize={12} color={'red'}>
            空欄になっている箇所があります。正しい生年月日を選択してください。
          </Typography>
        )}
      </Stack>
    </div>
  )
}
