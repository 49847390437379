import React, { FC, useState } from 'react'
import {
  AppBar,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material'
import { useEffect } from 'react'
import { MedChangeLog } from '../types/models/MedChangeLog'
import { getMedChangeLog } from '../apis/medChangeLog'
import { PlusIcon } from '../icons/PlusIcon'
import { useMedChangeModal } from '../hooks/useMedChangeModal'
import { formatDateTimeToDate } from '../helpers/formatDateTimeToDate'
import useReactRouter from 'use-react-router'
import { useLoading } from '../hooks/useLoading'
import { CustomButton } from '../components/molecules/CustomButton'
import { CustomFab } from '../components/molecules/CustomFab'

export const MedChangePage: FC = () => {
  const { history } = useReactRouter()
  const [medChangeLogs, setMedChangeLogs] = useState<MedChangeLog[]>([])
  const [index, setIndex] = useState<number>()
  const { LoadingOverlay, setIsLoading } = useLoading()
  const theme = useTheme()

  const [
    selectedMedChangeLog,
    setSelectedMedChangeLog,
  ] = useState<MedChangeLog>()

  const fetchMedChangeLog = async () => {
    try {
      const { data } = await getMedChangeLog()
      setMedChangeLogs(data)
    } catch (e) {
      console.log(e)
    }
  }

  const {
    openModal: openNewModal,
    renderModal: renderNewModal,
  } = useMedChangeModal(fetchMedChangeLog)

  const {
    openModal: openEditModal,
    renderModal: renderEditModal,
  } = useMedChangeModal(fetchMedChangeLog, selectedMedChangeLog)

  useEffect(() => {
    fetchMedChangeLog()
    index !== undefined && setSelectedMedChangeLog(medChangeLogs[index])
    setIsLoading(false)
  }, [index])

  return (
    <>
      {LoadingOverlay}
      <AppBar
        position="static"
        sx={{ bgcolor: theme.palette.primary.dark, color: '#fff' }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <CustomButton variant="contained" onClick={() => history.push('/')}>
            処方画面へ戻る
          </CustomButton>
          <Typography variant="h5">採用変更登録</Typography>
        </Toolbar>
      </AppBar>
      <Stack>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ bgcolor: theme.palette.secondary.main }}>
              <TableRow>
                <TableCell>一般名</TableCell>
                <TableCell>変更前</TableCell>
                <TableCell>変更後</TableCell>
                <TableCell>変更開始日</TableCell>
                <TableCell>アラート終了日</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {medChangeLogs.map((item, index) => (
                <TableRow
                  key={index}
                  onClick={() => {
                    setIndex(index)
                    openEditModal()
                  }}
                >
                  <TableCell>{item.med_ippan_name}</TableCell>
                  <TableCell>{item.original_med_name}</TableCell>
                  <TableCell>{item.updated_med_name}</TableCell>
                  <TableCell>{formatDateTimeToDate(item.start_date)}</TableCell>
                  <TableCell>{formatDateTimeToDate(item.end_date)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <CustomFab onClick={openNewModal}>
        <PlusIcon />
      </CustomFab>
      {renderNewModal()}
      {renderEditModal()}
    </>
  )
}
