import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { EditableInsuranceNumModalBody } from '../components/templates/EditableInsuranceNumModalBody'
import { BasicResult, EditBasicResultKey } from '../types/models/Presc'
import { useModal } from './useModal'
import Amplitude from '../utils/analytics/amplitude'
import { localStorageWrapper } from '../helpers/localStorage'

export const useInsuranceNumberEditModal = (
  prescId: string,
  basicResult: BasicResult,
  editBasicResult: (key: EditBasicResultKey) => void
) => {
  const [insuranceNumberStr, setInsuranceNumberStr] = useState(
    localStorageWrapper.getItem('allowedInsuranceNumBlank')
      ? ''
      : basicResult.id_22_insurance_patient_num.selected_option
  )

  useEffect(() => {
    setInsuranceNumberStr(
      localStorageWrapper.getItem('allowedInsuranceNumBlank')
        ? ''
        : basicResult.id_22_insurance_patient_num.selected_option
    )
  }, [prescId])

  const completeEdit = useCallback(() => {
    const numberBeforeEdit =
      basicResult.id_22_insurance_patient_num.selected_option
    editBasicResult({
      key: 'id_22_insurance_patient_num',
      value: insuranceNumberStr,
    })
    closeEditableModal()
    Amplitude.logEvent({
      event: 'editInsuranceNum',
      properties: {
        before: numberBeforeEdit,
        after: insuranceNumberStr,
      },
    })
  }, [insuranceNumberStr])

  const {
    openModal: openEditableModal,
    closeModal: closeEditableModal,
    renderModal: renderEditableModal,
  } = useModal(
    <EditableInsuranceNumModalBody
      insuranceNumberStr={insuranceNumberStr}
      setInsuranceNumberStr={setInsuranceNumberStr}
      completeEdit={completeEdit}
      options={basicResult['id_22_insurance_patient_num']['options']}
      initialVal={basicResult['id_22_insurance_patient_num']['selected_option']}
    />,
    'right'
  )

  return { openEditableModal, renderEditableModal }
}
