import React, { useState, useCallback, FC } from 'react'
import { memo, NamedExoticComponent } from 'react'
import { Prescription } from '../types/models/Presc'
import { Box, Button } from '@mui/material'
import { MenuIcon } from '../icons/MenuIcon'
import { DrawerPrescList } from '../components/organisms/Drawer'
import { Pharmacy } from '../types/models/Pharmacy'
import { PharmacyDrawerList } from '../components/organisms/PharmacyDrawer'
import { usePharmacy } from './usePharmacy'

export const useAnnotatePharmacyDrawerButton = () => {
  const [isOpen, setIsOpen] = useState(false)
  const open = useCallback(() => setIsOpen(true), [])
  const close = useCallback(() => setIsOpen(false), [])

  const { pharmacies, selectedPharmacy, selectPharmacy } = usePharmacy()

  const AnnotatePharmacyDrawerButton = (
    <>
      <Box
        sx={{
          opacity: 0.8,
        }}
        position="fixed"
        zIndex={1300}
        left={0}
        bottom={0}
        bgcolor={'primary.dark'}
        color="#fff"
        p={1}
        borderRadius="0px 8px 0px 0px"
      >
        <Button
          onClick={open}
          startIcon={<MenuIcon />}
          style={{ color: '#fff' }}
        >
          {selectedPharmacy ? selectedPharmacy.name : ''}
        </Button>
      </Box>
      <PharmacyDrawerList
        isOpen={isOpen}
        onClose={close}
        pharmacies={pharmacies}
        selectedPharmacy={selectedPharmacy}
        selectPharmacy={selectPharmacy}
      />
    </>
  )

  return { AnnotatePharmacyDrawerButton, selectedPharmacy }
}
