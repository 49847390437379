import { Snackbar, useTheme } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getAdditionalPresc } from '../apis/getAdditionalPresc'
import { getPrescriptions } from '../apis/prescription'
import { getUniquePrescriptions } from '../helpers/getUniquePrescriptions'

import { GlobalState } from '../stores/reducer'
import { Prescription } from '../types/models/Presc'
import { useLoading } from './useLoading'

export const usePrescriptions = () => {
  const theme = useTheme()
  const [prescriptions, setPrescriptions] = useState<Prescription[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const prescriptionsRef = useRef(prescriptions)
  const isLoggedIn = useSelector((state: GlobalState) => state.isLoggedIn)

  const { LoadingOverlay, setIsLoading } = useLoading()

  useEffect(() => {
    if (!isLoggedIn) return
    const intervalId = window.setInterval(loadPrescriptions, 1000 * 1)
    return () => {
      clearInterval(intervalId)
    }
  }, [isLoggedIn])

  const loadPrescriptions = () => {
    prescriptionsRef.current.length === 0
      ? loadInitialPrescriptions()
      : appendAdditionalPrescriptions()
  }

  const loadInitialPrescriptions = async () => {
    try {
      const { data } = await getPrescriptions()
      console.log('presc_from_back', data)
      prescriptionsRef.current = data
      setPrescriptions(data)
    } catch (e) {
      console.log(e)
    }
    setIsLoading(false)
  }

  const appendAdditionalPrescriptions = async () => {
    try {
      const { data: additionalPresc } = await getAdditionalPresc(
        prescriptionsRef.current[0].request_event_created_at
      )
      additionalPresc.length !== 0 && setIsOpen(true)
      const additionalPrescriptions = [
        ...additionalPresc,
        ...prescriptionsRef.current,
      ]
      //uniqueな処方箋を取得
      const uniquePrescriptions = getUniquePrescriptions(
        additionalPrescriptions
      )
      prescriptionsRef.current = uniquePrescriptions
      setPrescriptions(uniquePrescriptions)
    } catch (e) {
      console.log(e)
    }
  }

  const AdditionalPrescSnackBar = () => (
    <div>
      <Snackbar
        style={{ zIndex: 101, marginRight: '60px' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isOpen}
        autoHideDuration={3000}
        onClose={() => setIsOpen(false)}
      >
        <MuiAlert
          sx={{ bgcolor: theme.palette.primary.translucent }}
          elevation={6}
          variant="filled"
          onClose={() => setIsOpen(false)}
        >
          新規読み取りが完了しました
        </MuiAlert>
      </Snackbar>
    </div>
  )

  return {
    AdditionalPrescSnackBar,
    prescriptions,
    loadInitialPrescriptions,
    LoadingOverlay,
  }
}
