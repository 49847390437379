import React, { FC, useState } from 'react'
import { Stack } from '@mui/material'
import { InputBox } from '../molecules/InputBox'
import {
  changeIntoHankakuKana,
  isHankakuKana,
  isKana,
} from '../../helpers/isHankaku'
import { CustomButton } from '../molecules/CustomButton'
import { EditNameBox } from '../organisms/EditNameBox'

type Props = {
  initialFirstName: string
  firstName: string
  firstNameList: string[]
  setFirstName: (firstName: string) => void
  initialLastName: string
  lastName: string
  lastNameList: string[]
  setLastName: (lastName: string) => void
  completeEdit: () => void
}

export const EditableKanaModalBody: FC<Props> = ({
  firstName,
  initialFirstName,
  firstNameList,
  setFirstName,
  initialLastName,
  lastName,
  lastNameList,
  setLastName,
  completeEdit,
}) => {
  const isValidFirstName = isKana(firstName) || firstName === 'XXX'
  const isValidLastName = isKana(lastName) || lastName === 'XXX'
  const message = 'ひらがな、カタカナ、あるいは半角カナで入力してください'

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '8px',
      }}
    >
      <Stack direction="row" spacing={1}>
        <EditNameBox
          label="患者姓ｶﾅ"
          options={firstNameList}
          value={firstName}
          setValue={setFirstName}
          isInitialState={initialFirstName === firstName}
          inputType={'text'}
          isError={!isValidFirstName}
          helperText={!isValidFirstName ? message : ''}
          onBlur={() => {
            setFirstName(changeIntoHankakuKana(firstName))
          }}
          autoFocus={true}
        />
        <EditNameBox
          label="患者名ｶﾅ"
          options={lastNameList}
          value={lastName}
          setValue={setLastName}
          isInitialState={initialLastName === lastName}
          inputType={'text'}
          isError={!isValidLastName}
          helperText={!isValidLastName ? message : ''}
          onBlur={() => {
            setLastName(changeIntoHankakuKana(lastName))
          }}
          autoFocus={true}
        />

        <CustomButton
          sx={{
            color: '#3F51B5',
            bgcolor: '#E9F1F8',
            border: '2px solid ',
            borderColor: '3F51B5',
            height: '32px',
          }}
          onClick={completeEdit}
          disabled={!isValidFirstName || !isValidLastName}
        >
          確定
        </CustomButton>
      </Stack>
    </div>
  )
}
