import client from './client'
import { AxiosPromise } from 'axios'
import dayjs, { Dayjs } from 'dayjs'

import { MedChangeLog } from '../types/models/MedChangeLog'

export const getMedChangeLog = (): AxiosPromise<MedChangeLog[]> =>
  client.get('/med_change_log')

export const postMedChangeLog = (
  med_ippan_name: string,
  original_med_name: string,
  updated_med_name: string,
  start_date: Dayjs,
  end_date: Dayjs,
  is_scheduled: boolean
): AxiosPromise<MedChangeLog[]> => {
  const params = {
    is_scheduled,
    med_ippan_name,
    original_med_name,
    updated_med_name,
    start_date,
    end_date,
  }
  return client.post('/med_change_log/', params, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const patchMedChangeLog = (
  id: string,
  med_ippan_name: string,
  original_med_name: string,
  updated_med_name: string,
  start_date: Dayjs,
  end_date: Dayjs,
  is_scheduled: boolean
): AxiosPromise<MedChangeLog[]> => {
  const params = {
    is_scheduled,
    med_ippan_name,
    original_med_name,
    updated_med_name,
    start_date,
    end_date,
  }
  return client.patch(`/med_change_log/${id}/`, params, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const deleteMedChangeLog = (
  id: string
): AxiosPromise<MedChangeLog[]> => {
  return client.delete(`/med_change_log/${id}`)
}
