import { Typography, Box } from '@mui/material'
import { localStorageWrapper } from '../../helpers/localStorage'
import ScannerImage from '../../icons/scanner.png'
import { Copyright } from '../molecules/Copyright'

export const InitialScreenBody = () => {
  return (
    <div>
      <Box p={2}>
        <img
          src={ScannerImage}
          alt="画像"
          style={{ width: '100%', opacity: '0.5' }}
        />
      </Box>
      <Copyright />
    </div>
  )
}
