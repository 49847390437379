import amplitude from 'amplitude-js'
import { AMPLITUDE_ID } from '../../constants/const'
const init = (): void => {
  AMPLITUDE_ID && amplitude.getInstance().init(AMPLITUDE_ID)
}
const optOut = (): void => {
  getInstance().setOptOut(true)
}
const optIn = (): void => {
  getInstance().setOptOut(false)
}
const getInstance = () => amplitude.getInstance()
type Properties = { [key in string]: string | number }
type LogEventParams = {
  event: string
  properties?: Properties
}
const logEvent = ({ event, properties }: LogEventParams): void => {
  getInstance().logEvent(event, properties)
}
const pageView = (pagePath: string): void => {
  logEvent({ event: `page_view ${pagePath}` })
}
const setUserProperties = (
  properties: Properties & { userId: string }
): void => {
  getInstance().setUserId(String(properties.userId))
  getInstance().setUserProperties(properties)
}
const removeUserProperties = (): void => {
  getInstance().setUserId(null)
}
const Amplitude = {
  init,
  logEvent,
  pageView,
  setUserProperties,
  removeUserProperties,
  optOut,
  optIn,
}
export default Amplitude
