import client from './client'
import { AxiosPromise, AxiosResponse } from 'axios'
import { SupportChatMessage } from '../types/models/SupportChatMessage'

export const getMessages = (): AxiosPromise<SupportChatMessage[]> =>
  client.get(`/support_chat_messages/`)

export const postMessage = (text: string): AxiosPromise<string> => {
  const params = { text }
  return client.post(`/support_chat_messages/`, params)
}

export const getNewMessages = (): AxiosPromise<SupportChatMessage[]> =>
  client.get(`/support_chat_messages/latest/`)
