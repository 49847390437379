import Button from '@mui/material/Button'
import { IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'

export const CustomButton = styled(Button)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}))

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.dark,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}))
