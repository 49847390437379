import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { checkAuth } from '../apis/auth'
import { localStorageWrapper } from '../helpers/localStorage'
import { GlobalState } from '../stores/reducer'

export const useGetAppType = () => {
  const isLoggedIn = useSelector((state: GlobalState) => state.isLoggedIn)
  const [appType, setAppType] = useState(
    localStorageWrapper.getItem('app_type') || 'Medilab'
  )
  useEffect(() => {
    if (!isLoggedIn) return
    setAppType(localStorageWrapper.getItem('app_type') || 'Medilab')
  }, [isLoggedIn])

  return appType
}
