import { useState, useCallback, FC } from 'react'
import { memo, NamedExoticComponent } from 'react'
import { Prescription } from '../types/models/Presc'
import { Box, Button, useTheme } from '@mui/material'
import { MenuIcon } from '../icons/MenuIcon'
import { DrawerPrescList } from '../components/organisms/Drawer'
import { localStorageWrapper } from '../helpers/localStorage'
import { SERVICE_NAMES } from '../constants/const'

type Props = {
  selectedPresc?: Prescription
  PrescImageToggleButton: JSX.Element
  prescriptions: Prescription[]
  selectOcrRequest: (ocrRequestId: string) => void
  BottomButtons: React.ReactNode[]
}

export const DrawerToggleButton: NamedExoticComponent<Props> = memo(
  ({
    selectedPresc,
    PrescImageToggleButton,
    prescriptions,
    selectOcrRequest,
    BottomButtons,
  }) => {
    const [isOpen, setIsOpen] = useState(false)
    const theme = useTheme()
    const open = useCallback(() => setIsOpen(true), [])
    const close = useCallback(() => setIsOpen(false), [])

    const company = localStorageWrapper.getItem('app_type') || 'Medilab'
    const serviceName =
      company === 'TMN' ? SERVICE_NAMES.TMN : SERVICE_NAMES.DEFAULT

    return (
      <>
        <Box
          sx={{
            [theme.breakpoints.down('md')]: {
              bottom: '0',
              borderRadius: '0px 8px 0px 0px',
            },
            opacity: 0.8,
          }}
          position="fixed"
          zIndex={1300}
          left={0}
          bgcolor={'primary.dark'}
          color="#fff"
          p={1}
          borderRadius="0px 0px 8px 0px"
        >
          <Button
            onClick={open}
            startIcon={<MenuIcon />}
            style={{ color: '#fff' }}
          >
            {selectedPresc ? selectedPresc.label : serviceName}
          </Button>
        </Box>
        {PrescImageToggleButton}
        <DrawerPrescList
          isOpen={isOpen}
          onClose={close}
          prescriptions={prescriptions}
          selectedPresc={selectedPresc}
          selectOcrRequest={selectOcrRequest}
          BottomButtons={BottomButtons}
        />
      </>
    )
  }
)

DrawerToggleButton.displayName = 'DrawerOpenButton'
