import axios from 'axios'
import { API_HOST } from '../constants/const'
import Amplitude from '../utils/analytics/amplitude'

let client
console.log('API_HOST: ', API_HOST)

export default client = axios.create({
  baseURL: `${API_HOST}`,
  headers: {
    'Content-Type': 'application/json',
    REFERER: 'http://127.0.0.1:3000',
  },
  withCredentials: true,
})

client.interceptors.response.use((response) => {
  const data = response.data
  return { ...response.data, data }
})

client.interceptors.request.use(
  (config) => {
    if (config.method?.toLowerCase() === 'post') {
      const requestData = JSON.stringify(config.data)
      Amplitude.logEvent({
        event: 'API_REQUEST_START',
        properties: {
          url: config.url || 'unknown',
          method: config.method || 'unknown',
          data: requestData,
        },
      })
    }
    return config
  },
  (error) => {
    if (error.config?.method?.toLowerCase() === 'post') {
      const requestData = error.config.data
        ? JSON.stringify(error.config.data)
        : 'unknown'
      Amplitude.logEvent({
        event: 'API_REQUEST_ERROR',
        properties: {
          errorMessage: error.message,
          data: requestData,
        },
      })
    }
    return Promise.reject(error)
  }
)

client.interceptors.response.use(
  (response) => {
    if (response.config.method?.toLowerCase() === 'post') {
      Amplitude.logEvent({
        event: 'API_RESPONSE_SUCCESS',
        properties: {
          url: response.config.url || 'unknown',
          method: response.config.method || 'unknown',
          statusCode: response.status,
        },
      })
    }
    return response
  },
  (error) => {
    if (error.config?.method?.toLowerCase() === 'post') {
      Amplitude.logEvent({
        event: 'API_RESPONSE_ERROR',
        properties: {
          url: error.config.url || 'unknown',
          method: error.config.method || 'unknown',
          errorMessage: error.message,
          statusCode: error.response ? error.response.status : 'unknown',
        },
      })
    }
    return Promise.reject(error)
  }
)
