import React, { FC, useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { ButtonBase, TextField, Typography } from '@mui/material'
import { CSSProperties } from 'react'
import { keyframes } from '@emotion/react'

type Props = {
  label: string
  value: string
  maxWidth?: CSSProperties['maxWidth']
  hasHighCheckPriority?: boolean
  onClick: () => void
  isHighlighted: boolean
}
const StyledInputBox = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    height: '32px',
    borderRadius: '0',
  },
  width: '100%',
  '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
    transform: 'translate(20%, 20%)',
  },
})

export const HighlightInputBox: FC<Props> = ({
  label,
  value,
  hasHighCheckPriority,
  maxWidth,
  onClick,
  isHighlighted,
}) => {
  const theme = useTheme()
  const inputBoxColor = isHighlighted
    ? theme.palette.primary.dark
    : theme.palette.secondary.main

  return (
    <ButtonBase style={{ width: '100%', maxWidth: maxWidth }}>
      <StyledInputBox
        label={label}
        value={value}
        onClick={onClick}
        style={{
          maxWidth: maxWidth,
          backgroundColor: hasHighCheckPriority
            ? theme.palette.warning.light
            : '#fff',
        }}
        InputProps={{
          endAdornment: isHighlighted && <Typography>✔️</Typography>,
          readOnly: true,
        }}
        InputLabelProps={{
          sx: {
            color: inputBoxColor,
            '&.Mui-focused': {
              color: inputBoxColor,
            },
          },
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: inputBoxColor,
              borderWidth: isHighlighted ? '2px' : '1px',
            },
          },
          '& .MuiOutlinedInput-root.Mui-focused': {
            '& > fieldset': {
              borderColor: inputBoxColor,
            },
          },
        }}
      />
    </ButtonBase>
  )
}
