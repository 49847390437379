import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material'
import { postFeatureRequest } from '../../apis/dashboard'
import React from 'react'

export const FeatureRequestModalBody = ({
  onSubmit,
}: {
  onSubmit: () => void
}) => {
  const [request, setRequest] = React.useState<string>('')

  return (
    <div style={{ marginLeft: 16, marginRight: 16, marginBottom: 16 }}>
      <Typography variant="h5" sx={{ marginBottom: 3 }}>
        ご要望入力
      </Typography>
      <TextField
        label="ご要望を一行程度でご入力ください"
        multiline
        rows={3}
        value={request}
        onChange={(e) => {
          setRequest(e.target.value)
        }}
        sx={{ width: '100%', marginBottom: '16px' }}
      />
      <Stack direction="row-reverse" sx={{ marginTop: 1 }}>
        <Button
          variant="contained"
          onClick={() => {
            postFeatureRequest(request).then(() => {
              onSubmit()
            })
          }}
        >
          送信
        </Button>
      </Stack>
    </div>
  )
}
