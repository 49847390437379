import React, { FC, useState, useRef, useEffect } from 'react'
import { TextField, Box, List, ListItem } from '@material-ui/core'
import { Stack } from '@mui/material'

type Props = {
  label: string
  options: string[]
  value: string | null
  setValue: React.Dispatch<React.SetStateAction<string | null>>
}

export const SearchableSelectBox: FC<Props> = ({
  label,
  options,
  value,
  setValue,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState<string | null>(value)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const filteredOptions = options.filter((option) => {
    return option.startsWith(inputValue || '')
  })
  const limitedOptions = filteredOptions.slice(0, 100)

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        containerRef.current &&
        e.target instanceof Node &&
        !containerRef.current.contains(e.target)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
    <Box position="relative" width="100%" {...{ ref: containerRef }}>
      <TextField
        fullWidth
        variant="outlined"
        label={label}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onClick={(e) => {
          e.stopPropagation()
          if (!isOpen) {
            setIsOpen(true)
          }
        }}
        style={{ cursor: 'pointer' }}
      />
      {isOpen && (
        <Box
          style={{
            position: 'absolute',
            width: '100%',
            maxHeight: '200px',
            overflowY: 'auto',
            background: '#fff',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
            zIndex: 1500,
          }}
        >
          <List>
            {limitedOptions.map((option, index) => (
              <ListItem
                key={index}
                onClick={() => {
                  setValue(option)
                  setInputValue(option)
                  setIsOpen(false)
                }}
              >
                {option}
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  )
}
