import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getNewMessages } from '../apis/supportChatMessages'

import { GlobalState } from '../stores/reducer'
import { SupportChatMessage } from '../types/models/SupportChatMessage'
import { ChatNotificationSnackbar } from '../components/organisms/ChatNotificationSnackBar'

export const useSupportChatNotification = () => {
  const isLoggedIn = useSelector((state: GlobalState) => state.isLoggedIn)

  useEffect(() => {
    if (!isLoggedIn) return
    const intervalId = window.setInterval(loadNewMessages, 1000 * 30)
    return () => {
      clearInterval(intervalId)
    }
  }, [isLoggedIn])

  const [newMessages, setNewMessages] = useState<SupportChatMessage[]>([])

  const loadNewMessages = async () => {
    try {
      const { data } = await getNewMessages()
      if (data.length > 0) {
        setNewMessages(data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const ChatNotificationSnackBars = newMessages.map((message, i) => (
    <ChatNotificationSnackbar key={message.id} message={message} index={i} />
  ))
  return { ChatNotificationSnackBars }
}
