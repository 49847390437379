export const formatTimeInJP = (time: string): string => {
  const timeInJp = new Date(time)

  //時間と分を二桁に統一する
  const hours = ('0' + timeInJp.getHours().toString()).slice(-2)
  const minutes = ('0' + timeInJp.getMinutes().toString()).slice(-2)

  return hours + ':' + minutes
}

export const formatTimeInJPFull = (time: string): string => {
  const timeInJp = new Date(time)

  //時間と分を二桁に統一する
  const month = ('0' + (timeInJp.getMonth() + 1).toString()).slice(-2)
  const day = ('0' + timeInJp.getDate().toString()).slice(-2)
  const hours = ('0' + timeInJp.getHours().toString()).slice(-2)
  const minutes = ('0' + timeInJp.getMinutes().toString()).slice(-2)

  return month + '/' + day + '　' + hours + ':' + minutes
}
