import { Box, Stack, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { localStorageWrapper } from '../helpers/localStorage'

export const useBasicResult = () => {
  const theme = useTheme()
  const [essentialBasicResultShown, setEssentialBasicResultShown] = useState(
    true
  )

  const tintColor = theme.palette.secondary.sub
  const baseColor = theme.palette.secondary.main

  const tabBoxProps = (isSelected: boolean) => {
    return {
      sx: {
        bgcolor: isSelected ? tintColor : baseColor,
      },
      width: '50%',
      color: '#fff',
      borderRadius: '8px 8px 0px 0px',
      textAlign: 'center' as const,
    }
  }

  const BasicResultsTab = (
    <Stack direction="row">
      <Box
        {...tabBoxProps(essentialBasicResultShown)}
        onClick={() => setEssentialBasicResultShown(true)}
      >
        必須患者情報
      </Box>
      <Box
        {...tabBoxProps(!essentialBasicResultShown)}
        onClick={() => setEssentialBasicResultShown(false)}
      >
        {'その他患者情報'}
      </Box>
    </Stack>
  )

  return { essentialBasicResultShown, BasicResultsTab }
}
