import { CircularProgress, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'

export const useLoading = () => {
  const [isLoading, setIsLoading] = useState(true)

  const LoadingOverlay = isLoading && (
    <Stack
      sx={{
        zIndex: 1305,
        position: 'absolute',
        bgcolor: 'rgb(0,0,0, 0.4)',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      spacing={2}
    >
      <CircularProgress style={{ width: '100px', height: '100px' }} />
      <Typography sx={{ color: '#fff', fontSize: '24px' }}>
        読み込み中...
      </Typography>
    </Stack>
  )

  return { LoadingOverlay, setIsLoading }
}
