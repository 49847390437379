import { CheckBox, BasicResult } from '../types/models/Presc'

export const addIsSelectedProp = (
  key: keyof BasicResult,
  checkboxList: CheckBox[],
  basicResult: BasicResult
) => {
  const newCheckboxList = checkboxList.map((info) => ({
    ...info,
    isSelected: basicResult[key]['selected_option'] === info.value,
  }))
  return newCheckboxList
}
