import { Card, Stack, Grid, Typography } from '@mui/material'
import { FC } from 'react'

type PanelGroupProps = {
  title: string
  patient_count: number
  image_count: number
  rp_count: number
  med_count: number
}

type PanelProps = {
  label: string
  count: number
  unit: string
}

export const DashboardSquarePanelGroup: FC<PanelGroupProps> = ({
  title,
  patient_count,
  image_count,
  rp_count,
  med_count,
}) => {
  return (
    <Stack
      direction="column"
      sx={{
        height: '100%',
      }}
    >
      <Typography variant="h6" align="center">
        {title}
      </Typography>
      <Grid container style={{ height: '200px' }}>
        <Grid
          item
          xs={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <DashboardSquarePanel
            label="累計患者"
            count={patient_count}
            unit="名"
          />
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <DashboardSquarePanel
            label="累計処方箋"
            count={image_count}
            unit="枚"
          />
        </Grid>
        <Grid container item xs={4}>
          <Grid item xs={12}>
            <DashboardHalfSquarePanel
              label="累計調剤"
              count={rp_count}
              unit="調剤"
            />
          </Grid>
          <Grid item xs={12}>
            <DashboardHalfSquarePanel
              label="累計薬剤"
              count={med_count}
              unit="薬剤"
            />
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  )
}

const DashboardSquarePanel: FC<PanelProps> = ({ label, count, unit }) => {
  const styles = {
    card: {
      border: '1px solid #ccc',
      backgroundColor: 'white',
      width: '100%',
      height: '100%',
    },
    header: {
      fontSize: '16px',
      fontWeight: 'normal' as const,
      margin: 0,
      padding: '5px',
      textAlign: 'left' as const,
    },
    count: {
      fontSize: '64px',
      textAlign: 'center' as const,
      margin: '0px',
      padding: '10%',
    },
    unit: {
      fontSize: '32px',
    },
  }
  return (
    <Card elevation={3} style={styles.card}>
      <Typography variant="h2" style={styles.header}>
        {label}
      </Typography>
      <Typography variant="body1" style={styles.count}>
        <span>{count}</span>
        <span style={styles.unit}>{unit}</span>
      </Typography>
    </Card>
  )
}

const DashboardHalfSquarePanel: FC<PanelProps> = ({ label, count, unit }) => {
  const styles = {
    card: {
      border: '1px solid #ccc',
      backgroundColor: 'white',
      width: '100%',
      height: '100%',
    },
    header: {
      fontSize: '16px',
      fontWeight: 'normal' as const,
      margin: '0px',
      padding: '5px',
      textAlign: 'left' as const,
    },
    count: {
      fontSize: '32px',
      textAlign: 'center' as const,
      margin: '0px',
      padding: '0px',
    },
    unit: {
      fontSize: '32px',
    },
  }

  return (
    <Card style={styles.card} elevation={3}>
      <Typography variant="h6" style={styles.header}>
        {label}
      </Typography>
      <Typography variant="body1" style={styles.count}>
        <span>{count}</span>
        <span style={styles.unit}>{unit}</span>
      </Typography>
    </Card>
  )
}
