import client from './client'
import { AxiosPromise } from 'axios'

import { IppanMedicine, SpecificMedicine } from '../types/models/Medicines'

export const getIppanMedicines = (): AxiosPromise<IppanMedicine[]> =>
  client.get('/ippan_medicines')

export const getSpecificMedicines = (
  ippan_med_id: string
): AxiosPromise<SpecificMedicine[]> =>
  client.get(`/ippan_medicines/${ippan_med_id}/specific_medicines`)
