import { Box, Stack, Typography } from '@mui/material'
import React, { FC } from 'react'
import { CircleExclamationIcon } from '../../icons/CircleExclamationIcon'

export const UpdateNotifyModalBody: FC = () => {
  return (
    <Box sx={{ zIndex: 1400, pl: 8, pr: 8 }} overflow="scroll" height="90%">
      <Typography color={'secondary.dark'}>
        <h2>『精度調整依頼ボタン・まもる君チャット機能更新』のお知らせ</h2>
      </Typography>
      <Typography textAlign="left" fontSize={20}>
        よりAI性能を向上させる為、この度以下アップデートをいたしました。
        <br />
        お手隙の際にご確認頂ければ幸いです。
        <br />
        <br />
        <b>◾️新機能</b>
        <br />
        <b>①患者情報ミスをAIに教える</b> : 該当の項目をタップください。
        <br />
        <br />
        <b>②医薬品情報ミスをAIに教える</b> : 該当のRPをタップください。
        <br />
        <br />
        <b>③医薬品の漏れを教える</b> : 右下にある
        <CircleExclamationIcon style={{ transform: 'translate(0%,20%)' }} />
        をタップください。
        <br />
        <br />
        <b>④読み取り内容についてより細かいリクエストを教える</b> :
        <br />
        右下にいるチャット学習AIのまもる君を開き、リクエストをお伝えください。
        <br />
        <br />- 操作画面デモ動画→
        <a href="https://drive.google.com/file/d/1aEd70vRpRdZltUB1JEkrpnichgai5Pom/view?usp=share_link">
          こちら
        </a>
        <br />
        <br />
        <b>◾️テスト用処方箋データ表示期間</b>
        <br />
        本機能を実際にお試しいただくために
        <b>14日(水)〜17日(土)の間、テスト用処方箋データを表示</b>
        いたします。ぜひご活用ください。
        <br />
        <br />
        引き続き皆さまをよりご支援すべく、様々な機能アップデートを行ってまいります。どうぞ、よろしくお願いいたします。
        <br />
        <br />
        mediLab サービス開発チーム一同
        <br />
      </Typography>
    </Box>
  )
}
