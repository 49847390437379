import { Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getAdditionalPresc } from '../apis/getAdditionalPresc'
import { getAnnotatePrescList, getPrescriptions } from '../apis/prescription'
import { getUniquePrescriptions } from '../helpers/getUniquePrescriptions'

import { GlobalState } from '../stores/reducer'
import { Pharmacy } from '../types/models/Pharmacy'
import { Prescription } from '../types/models/Presc'
import { useLoading } from './useLoading'

export const useAnnotatePresc = (selectedPharmacy: Pharmacy) => {
  const [prescriptions, setPrescriptions] = useState<Prescription[]>([])
  const isLoggedIn = useSelector((state: GlobalState) => state.isLoggedIn)

  useEffect(() => {
    if (!isLoggedIn) return
    if (!selectedPharmacy) return
    loadAnnotatePrescList()
  }, [isLoggedIn, selectedPharmacy])

  const loadAnnotatePrescList = async () => {
    try {
      const { data } = await getAnnotatePrescList(selectedPharmacy)
      console.log('presc_from_back', data)
      setPrescriptions(data)
    } catch (e) {
      console.log(e)
    }
  }

  return {
    prescriptions,
    loadAnnotatePrescList,
  }
}
