import React, { FC, memo, NamedExoticComponent, useCallback } from 'react'
import {
  PRESCRIPTION_INFO,
  INPUTBOX_MAX_WIDTH,
  EDITABLE_ITEM_KEYS,
  CHECKBOX_ITEM_KEYS,
  NON_EDITABLE_ITEM_KEYS,
  EDITABLE_ITEM_WITH_SELECT_BOX_KEYS,
  BASIC_RESULT_LABEL,
  DISPLAY_ITEMS,
  CHECKBOX_MAX_WIDTH,
} from '../../constants/const'
import { addIsSelectedProp } from '../../helpers/addIsSelectedPresc'
import {
  EditBasicResultKey,
  BasicResult,
  InputBoxKey,
  CheckBoxKey,
} from '../../types/models/Presc'
import { Checkboxes } from '../molecules/Checkbox'
import { useKanaEditModal } from '../../hooks/useKanaEditModal'
import { useInsuranceNumberEditModal } from '../../hooks/useInsuranceNumberEditModal'
import { useBirthdaySelectModal } from '../../hooks/useBirthdaySelectModal'
import { localStorageWrapper } from '../../helpers/localStorage'
import { HighlightInputBox } from '../molecules/HighlightInputBox'
import { SelectBox } from '../molecules/SelectBox'
import { EditableInputBox } from '../molecules/EditableInputBox'

type EssentialBasicResultProps = {
  editBasicResult: (key: EditBasicResultKey) => void
  reportBasicResult: (key: keyof BasicResult, isReported: boolean) => void
  basicResult: BasicResult
  prescId: string
  isCheckboxDisabled: boolean
  getHasHighCheckPriority: (user_check_priority: number) => boolean
}

type SubBasicResultProps = {
  editBasicResult: (key: EditBasicResultKey) => void
  reportBasicResult: (key: keyof BasicResult, isReported: boolean) => void
  basicResult: BasicResult
  prescId: string
  isCheckboxDisabled: boolean
  getHasHighCheckPriority: (user_check_priority: number) => boolean
}

export type EditableItemKey =
  | 'id_11_patient_name_kana'
  | 'id_22_insurance_patient_num'
  | 'id_13_patient_birthday'

type Props = {
  editBasicResult: (key: EditBasicResultKey) => void
  reportBasicResult: (key: keyof BasicResult, isReported: boolean) => void
  basicResult: BasicResult
  prescId: string
  getHasHighCheckPriority: (user_check_priority: number) => boolean
  isCheckboxDisabled: boolean
  isEssentialBasicResultShown: boolean
}

const essentialDisplayItems = (() => {
  // 患者漢字氏名を患者保険情報タブに移動(Patch)
  const displayItem =
    localStorageWrapper.getItem('essentialDisplayItems') || DISPLAY_ITEMS
  return displayItem
})()

const isEditable = (key: EditableItemKey) =>
  key === 'id_11_patient_name_kana'
    ? localStorageWrapper.getItem('isPatientKanaEditable')
    : key === 'id_22_insurance_patient_num'
    ? localStorageWrapper.getItem('isInsuranceNumEditable')
    : localStorageWrapper.getItem('isBirthdayEditable')

const subDisplayItems = (() => {
  const displayItem =
    localStorageWrapper.getItem('subDisplayItems') || DISPLAY_ITEMS
  return displayItem
})()

const EssentialBasicResult: FC<EssentialBasicResultProps> = ({
  editBasicResult,
  reportBasicResult,
  basicResult,
  prescId,
  isCheckboxDisabled,
  getHasHighCheckPriority,
}) => {
  const {
    openEditableModal: openKanaEditableModal,
    renderEditableModal: renderKanaEditableModal,
  } = useKanaEditModal(prescId, basicResult, editBasicResult)

  const {
    openEditableModal: openInsuranceNumEditableModal,
    renderEditableModal: renderInsuranceNumEditableModal,
  } = useInsuranceNumberEditModal(prescId, basicResult, editBasicResult)

  const {
    openEditableModal: openBirthdaySelectModal,
    renderEditableModal: renderBirthdaySelectModal,
  } = useBirthdaySelectModal(prescId, basicResult, editBasicResult)

  const editBasicResultFactory = useCallback(
    (key: keyof BasicResult) => (value: string) => {
      editBasicResult({
        key,
        value,
      })
    },
    [editBasicResult]
  )

  const openEditableModal = useCallback(
    (key: InputBoxKey) => {
      console.log('key', key)
      return key === 'id_11_patient_name_kana'
        ? openKanaEditableModal
        : key === 'id_22_insurance_patient_num'
        ? openInsuranceNumEditableModal
        : openBirthdaySelectModal
    },
    [
      openKanaEditableModal,
      openInsuranceNumEditableModal,
      openBirthdaySelectModal,
    ]
  )

  const getCheckBoxList = useCallback(
    (key: CheckBoxKey) => {
      return key === 'id_12_patient_sex'
        ? addIsSelectedProp(key, PRESCRIPTION_INFO.SEX_LIST, basicResult)
        : addIsSelectedProp(key, PRESCRIPTION_INFO.INSURANCE_LIST, basicResult)
    },
    [basicResult]
  )

  const makeEditableChoices = useCallback(
    (key: InputBoxKey) => {
      const editableChoice = [
        basicResult[key].selected_option,
        ...basicResult[key].options,
        '入力する',
      ]
      return editableChoice
    },
    [basicResult]
  )

  return (
    <>
      {essentialDisplayItems.map((item) => (
        <div key={item}>
          {EDITABLE_ITEM_KEYS.includes(item) &&
            (isEditable(item as EditableItemKey) ? (
              <EditableInputBox
                onClick={
                  isEditable(item as EditableItemKey)
                    ? openEditableModal(item as InputBoxKey)
                    : () =>
                        reportBasicResult(item, !basicResult[item].is_reported)
                }
                label={BASIC_RESULT_LABEL[item]}
                isHighlighted={basicResult[item].is_reported}
                value={basicResult[item].selected_option}
                maxWidth={INPUTBOX_MAX_WIDTH.patientInfo}
                hasHighCheckPriority={getHasHighCheckPriority(
                  basicResult[item].user_check_priority
                )}
              />
            ) : (
              <HighlightInputBox
                onClick={
                  isEditable(item as EditableItemKey)
                    ? openEditableModal(item as InputBoxKey)
                    : () =>
                        reportBasicResult(item, !basicResult[item].is_reported)
                }
                label={BASIC_RESULT_LABEL[item]}
                isHighlighted={basicResult[item].is_reported}
                value={basicResult[item].selected_option}
                maxWidth={INPUTBOX_MAX_WIDTH.patientInfo}
                hasHighCheckPriority={getHasHighCheckPriority(
                  basicResult[item].user_check_priority
                )}
              />
            ))}
          {EDITABLE_ITEM_WITH_SELECT_BOX_KEYS.includes(item) &&
            (isEditable(item as EditableItemKey) ? (
              <EditableInputBox
                onClick={
                  isEditable(item as EditableItemKey)
                    ? openEditableModal(item as InputBoxKey)
                    : () =>
                        reportBasicResult(item, !basicResult[item].is_reported)
                }
                label={BASIC_RESULT_LABEL[item]}
                isHighlighted={basicResult[item].is_reported}
                value={basicResult[item].selected_option}
                maxWidth={INPUTBOX_MAX_WIDTH.patientInfo}
                hasHighCheckPriority={getHasHighCheckPriority(
                  basicResult[item].user_check_priority
                )}
              />
            ) : (
              <HighlightInputBox
                onClick={
                  isEditable(item as EditableItemKey)
                    ? openEditableModal(item as InputBoxKey)
                    : () =>
                        reportBasicResult(item, !basicResult[item].is_reported)
                }
                label={BASIC_RESULT_LABEL[item]}
                isHighlighted={basicResult[item].is_reported}
                value={basicResult[item].selected_option}
                maxWidth={INPUTBOX_MAX_WIDTH.patientInfo}
                hasHighCheckPriority={getHasHighCheckPriority(
                  basicResult[item].user_check_priority
                )}
              />
            ))}
          {NON_EDITABLE_ITEM_KEYS.includes(item) && (
            <HighlightInputBox
              onClick={() =>
                reportBasicResult(item, !basicResult[item].is_reported)
              }
              label={BASIC_RESULT_LABEL[item]}
              isHighlighted={basicResult[item].is_reported}
              value={basicResult[item].selected_option}
              maxWidth={INPUTBOX_MAX_WIDTH.patientInfo}
              hasHighCheckPriority={getHasHighCheckPriority(
                basicResult[item].user_check_priority
              )}
            />
          )}
          {CHECKBOX_ITEM_KEYS.includes(item) && (
            <Checkboxes
              onChange={editBasicResultFactory(item)}
              label={BASIC_RESULT_LABEL[item]}
              checkboxList={getCheckBoxList(item as CheckBoxKey)}
              isDisabled={isCheckboxDisabled}
              maxWidth={CHECKBOX_MAX_WIDTH.patientInfo}
              hasHighCheckPriority={getHasHighCheckPriority(
                basicResult[item].user_check_priority
              )}
              isReported={basicResult[item].is_reported}
            />
          )}
        </div>
      ))}

      {renderKanaEditableModal()}
      {renderInsuranceNumEditableModal()}
      {renderBirthdaySelectModal()}
    </>
  )
}

const SubBasicResult: FC<SubBasicResultProps> = ({
  editBasicResult,
  reportBasicResult,
  basicResult,
  prescId,
  isCheckboxDisabled,
  getHasHighCheckPriority,
}) => {
  const {
    openEditableModal: openKanaEditableModal,
    renderEditableModal: renderKanaEditableModal,
  } = useKanaEditModal(prescId, basicResult, editBasicResult)

  const {
    openEditableModal: openInsuranceNumEditableModal,
    renderEditableModal: renderInsuranceNumEditableModal,
  } = useInsuranceNumberEditModal(prescId, basicResult, editBasicResult)

  const {
    openEditableModal: openBirthdaySelectModal,
    renderEditableModal: renderBirthdaySelectModal,
  } = useBirthdaySelectModal(prescId, basicResult, editBasicResult)

  const editBasicResultFactory = useCallback(
    (key: keyof BasicResult) => (value: string) => {
      editBasicResult({
        key,
        value,
      })
    },
    [editBasicResult]
  )

  const openEditableModal = useCallback(
    (key: InputBoxKey) => {
      console.log('key', key)
      return key === 'id_11_patient_name_kana'
        ? openKanaEditableModal
        : key === 'id_22_insurance_patient_num'
        ? openInsuranceNumEditableModal
        : openBirthdaySelectModal
    },
    [
      openKanaEditableModal,
      openInsuranceNumEditableModal,
      openBirthdaySelectModal,
    ]
  )

  const getCheckBoxList = useCallback(
    (key: CheckBoxKey) => {
      return key === 'id_12_patient_sex'
        ? addIsSelectedProp(key, PRESCRIPTION_INFO.SEX_LIST, basicResult)
        : addIsSelectedProp(key, PRESCRIPTION_INFO.INSURANCE_LIST, basicResult)
    },
    [basicResult]
  )

  const makeEditableChoices = useCallback(
    (key: InputBoxKey) => {
      const editableChoice = [
        basicResult[key].selected_option,
        ...basicResult[key].options,
        '入力する',
      ]
      return editableChoice
    },
    [basicResult]
  )

  return (
    <>
      {subDisplayItems.map((item) => (
        <div key={item}>
          {EDITABLE_ITEM_KEYS.includes(item) &&
            (isEditable(item as EditableItemKey) ? (
              <EditableInputBox
                onClick={
                  isEditable(item as EditableItemKey)
                    ? openEditableModal(item as InputBoxKey)
                    : () =>
                        reportBasicResult(item, !basicResult[item].is_reported)
                }
                label={BASIC_RESULT_LABEL[item]}
                isHighlighted={basicResult[item].is_reported}
                value={basicResult[item].selected_option}
                maxWidth={INPUTBOX_MAX_WIDTH.patientInfo}
                hasHighCheckPriority={getHasHighCheckPriority(
                  basicResult[item].user_check_priority
                )}
              />
            ) : (
              <HighlightInputBox
                onClick={
                  isEditable(item as EditableItemKey)
                    ? openEditableModal(item as InputBoxKey)
                    : () =>
                        reportBasicResult(item, !basicResult[item].is_reported)
                }
                label={BASIC_RESULT_LABEL[item]}
                isHighlighted={basicResult[item].is_reported}
                value={basicResult[item].selected_option}
                maxWidth={INPUTBOX_MAX_WIDTH.patientInfo}
                hasHighCheckPriority={getHasHighCheckPriority(
                  basicResult[item].user_check_priority
                )}
              />
            ))}
          {EDITABLE_ITEM_WITH_SELECT_BOX_KEYS.includes(item) &&
            (isEditable(item as EditableItemKey) ? (
              <SelectBox
                onChangeChoice={editBasicResultFactory(item)}
                isReported={basicResult[item].is_reported}
                label={BASIC_RESULT_LABEL[item]}
                choices={makeEditableChoices(item as InputBoxKey)}
                selectedChoice={basicResult[item].selected_option}
                maxWidth={INPUTBOX_MAX_WIDTH.patientInfo}
                hasHighCheckPriority={getHasHighCheckPriority(
                  basicResult[item].user_check_priority
                )}
                openEditModal={openEditableModal(item as InputBoxKey)}
              />
            ) : (
              <HighlightInputBox
                onClick={
                  isEditable(item as EditableItemKey)
                    ? openEditableModal(item as InputBoxKey)
                    : () =>
                        reportBasicResult(item, !basicResult[item].is_reported)
                }
                label={BASIC_RESULT_LABEL[item]}
                isHighlighted={basicResult[item].is_reported}
                value={basicResult[item].selected_option}
                maxWidth={INPUTBOX_MAX_WIDTH.patientInfo}
                hasHighCheckPriority={getHasHighCheckPriority(
                  basicResult[item].user_check_priority
                )}
              />
            ))}
          {NON_EDITABLE_ITEM_KEYS.includes(item) && (
            <HighlightInputBox
              onClick={() =>
                reportBasicResult(item, !basicResult[item].is_reported)
              }
              label={BASIC_RESULT_LABEL[item]}
              isHighlighted={basicResult[item].is_reported}
              value={basicResult[item].selected_option}
              maxWidth={INPUTBOX_MAX_WIDTH.patientInfo}
              hasHighCheckPriority={getHasHighCheckPriority(
                basicResult[item].user_check_priority
              )}
            />
          )}
          {CHECKBOX_ITEM_KEYS.includes(item) && (
            <Checkboxes
              onChange={editBasicResultFactory(item)}
              label={BASIC_RESULT_LABEL[item]}
              checkboxList={getCheckBoxList(item as CheckBoxKey)}
              isDisabled={isCheckboxDisabled}
              maxWidth={CHECKBOX_MAX_WIDTH.patientInfo}
              hasHighCheckPriority={getHasHighCheckPriority(
                basicResult[item].user_check_priority
              )}
              isReported={basicResult[item].is_reported}
            />
          )}
        </div>
      ))}

      {renderKanaEditableModal()}
      {renderInsuranceNumEditableModal()}
      {renderBirthdaySelectModal()}
    </>
  )
}

export const BasicResultMenu: NamedExoticComponent<Props> = memo(
  ({
    editBasicResult,
    reportBasicResult,
    basicResult,
    prescId,
    getHasHighCheckPriority,
    isCheckboxDisabled,
    isEssentialBasicResultShown,
  }) => (
    <>
      {isEssentialBasicResultShown ? (
        <EssentialBasicResult
          prescId={prescId}
          reportBasicResult={reportBasicResult}
          editBasicResult={editBasicResult}
          basicResult={basicResult}
          isCheckboxDisabled={isCheckboxDisabled}
          getHasHighCheckPriority={getHasHighCheckPriority}
        />
      ) : (
        <SubBasicResult
          prescId={prescId}
          reportBasicResult={reportBasicResult}
          editBasicResult={editBasicResult}
          basicResult={basicResult}
          isCheckboxDisabled={isCheckboxDisabled}
          getHasHighCheckPriority={getHasHighCheckPriority}
        />
      )}
    </>
  )
)

BasicResultMenu.displayName = 'BasicResultMenu'
