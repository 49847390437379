import React, { useState, useRef, useCallback, FC } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  useTheme,
} from '@mui/material'
import { CloseIcon } from '../icons/CloseIcon'
import prescImage from '../components/atoms/presc_image.png'

const canvas = document.createElement('canvas')

export const useManualCamera = (width: number, height: number) => {
  const theme = useTheme()
  const [images, setImages] = useState<string[]>([])
  const [isTakingPhoto, setIsTakingPhoto] = useState<boolean>(false)
  const videoRef = useRef<HTMLVideoElement | null>(null)

  canvas.width = width
  canvas.height = height

  const createContext = () => {
    const context = canvas.getContext('2d', {
      willReadFrequently: true,
    }) as CanvasRenderingContext2D
    return context
  }

  const appendNewImage = () => {
    if (!videoRef.current) return
    videoRef.current.pause()
    setIsTakingPhoto(true)
    setTimeout(() => {
      videoRef?.current?.play()
      setIsTakingPhoto(false)
    }, 1000)
    const context = createContext()
    context.drawImage(videoRef.current, 0, 0, width, height)
    const imageData = canvas.toDataURL()
    setImages([...images, imageData])
  }

  const activateCamera = useCallback(async () => {
    if (!videoRef.current) {
      return
    }
    const constraints = {
      audio: false,
      video: {
        facingMode: 'environment',
        width: { min: 0, max: width },
        height: { min: 0, max: height },
        aspectRatio: 3 / 4,
      },
    }

    const stream = await navigator.mediaDevices.getUserMedia(constraints)
    videoRef.current.srcObject = stream
    videoRef.current.play()
  }, [])

  type ImageBoxProps = {
    url: string
  }
  const ImageBox: FC<ImageBoxProps> = ({ url }) => (
    <Stack direction="row">
      <img src={`${url}`} width={90} height={120} />
      <IconButton
        component="span"
        size="small"
        onClick={() => setImages(images.filter((imageUrl) => imageUrl !== url))}
        style={{ transform: 'translate(-30px, -45px)' }}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  )

  const CameraWithButton = (
    <Stack
      direction="column"
      spacing={2}
      justifyContent="center"
      sx={{ alignItems: 'center' }}
    >
      <Stack direction="row" spacing={2} justifyContent={'center'}>
        {images.length === 0 ? (
          <img
            src={prescImage}
            style={{
              height: 120,
              width: 90,
            }}
          />
        ) : (
          images.map((url) => (
            <div key={url}>
              <ImageBox url={url} />
            </div>
          ))
        )}
      </Stack>
      <Box
        sx={{
          [theme.breakpoints.down('md')]: { width: '260%' },
          width: '90%',
        }}
      >
        <video
          style={{
            width: '30%',
            flex: 1,
          }}
          ref={videoRef}
          playsInline
        />
        <Box pt={1}>
          {isTakingPhoto ? (
            <CircularProgress
              style={{
                height: '32px',
                width: '32px',
                color: 'gray',
              }}
            />
          ) : (
            <Button
              disabled={isTakingPhoto}
              variant="contained"
              onClick={appendNewImage}
            >
              撮影
            </Button>
          )}
        </Box>
      </Box>
    </Stack>
  )

  const resetImage = () => {
    setImages([])
  }

  return {
    CameraWithButton,
    images,
    resetImage,
    activateCamera,
  }
}
