import React, { FC } from 'react'
import { Provider } from 'react-redux'
import { createStore as reduxCreateStore } from 'redux'
import { reducer } from './reducer'

const createStore = () => reduxCreateStore(reducer)
export const store = createStore()

export const AppProvider: FC = ({ children }) => (
  <Provider store={store}>{children}</Provider>
)
