import { useEffect, useState } from 'react'
import { Theme } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { checkAuth } from '../apis/auth'
import { localStorageWrapper } from '../helpers/localStorage'
import { GlobalState } from '../stores/reducer'
import { themeMedilab, themeTMN } from '../styles/theme'
import { useGetAppType } from './useGetAppType'

export const useGetTheme = () => {
  const isLoggedIn = useSelector((state: GlobalState) => state.isLoggedIn)
  const appType = useSelector((state: GlobalState) => state.appType)
  const [theme, setTheme] = useState<Theme>(themeMedilab)
  useEffect(() => {
    console.log('now_app', appType)
    setTheme(appType === 'Medilab' ? themeMedilab : themeTMN)
  }, [appType, isLoggedIn])

  return theme
}
