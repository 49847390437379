import client from './client'
import { AxiosPromise } from 'axios'
import { Prescription } from '../types/models/Presc'

export const editPresc = (
  key: string,
  prescription: Prescription
): AxiosPromise<Prescription> => {
  const params = { key, prescription }
  return client.post('/prescriptions/', params, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
